import { moduleImgs } from '@/assets'

const search = [
  {
    text: 'Accession',
    route: '/accession',
    imgUrl: moduleImgs.accessionImg
  },
  {
    text: 'Phenotype',
    route: '/phenotype',
    imgUrl: moduleImgs.phenotypeImg
  },
  {
    text: 'Population Structure',
    route: '/population',
    imgUrl: moduleImgs.popStructureImg
},
  {
    text: 'Variation',
    route: '/search/variation',
    imgUrl: moduleImgs.variationImg
  },
  {
    text: 'GWAS',
    route: '/search/gwas',
    imgUrl: moduleImgs.gwasImg
  },
  {
    text: 'sQTL',
    route: '/search/sqtl',
    imgUrl: moduleImgs.sqtlImg
  },
  {
    text: 'eQTL',
    route: '/search/eqtl',
    imgUrl: moduleImgs.eqtlImg
  },
  {
    text: 'Gene',
    route: '/search/gene',
    imgUrl: moduleImgs.geneImg
  },
  {
    text: 'Population Gene Expression',
    route: '/search/expression',
    imgUrl: moduleImgs.expressionImg
  },
  {
    text: 'Selection Signals',
    route: '/search/selection',
    imgUrl: moduleImgs.selectionImg
  },
  {
    text: 'Introgression',
    route: '/search/introgression',
    imgUrl: moduleImgs.introgressionImg
  }
]

const pangenome = [
  {
    text: 'Genome',
    route: '/pan/genome',
    imgUrl: moduleImgs.pangenomeImg
  },
  {
    text: 'Transcriptome',
    route: '/pan/transcriptome',
    imgUrl: moduleImgs.pantranImg
    
  },
  {
    text: 'PAV',
    route: '/pan/pav',
    imgUrl: moduleImgs.pavImg
  }
]

const tools = [
  {
    text: 'Genome Coordinate Converter',
    route: '/tools/converter',
    imgUrl: moduleImgs.converterImg
  },
  {
    text: 'BLAST',
    route: '/tools/blast',
    imgUrl: moduleImgs.blastImg
  },
  {
    text: 'Genome Browser',
    route: '/jbrowse2/tomato/?session=share-8fi5Zsyq4V&password=cg2s1',
    onClick: () => {
      window.open(
        'http://123.60.14.43/jbrowse2/tomato/?session=share-cDaxEMdibW&password=367af',
        '_blank'
      )
    },
    imgUrl: moduleImgs.genomeImg
  },
  {
    text: 'Haplotype Network Analysis',
    route: '/tools/analysis',
    imgUrl: moduleImgs.haplotypeImg
  },
  {
    text: 'Primer Design',
    route: '/tools/primer',
    imgUrl: moduleImgs.primerImg
  }
]

const imgRoutes = {
    search,
    pangenome,
    tools
  }
  
export default imgRoutes
