import { useEffect, useRef, useState } from 'react'
import {
  Select,
  message,
  InputNumber,
  Input,
  Button,
  Table,
  Modal,
  Anchor
} from 'antd'
import { TVDInputContainer, TVDTextarea } from '@/components'
import { blastType } from '@/types'
import { blastService } from '@/services'
import styles from './style.module.less'
import { ColumnsType } from 'antd/lib/table'

interface CanvasFuncParams {
  ctx: CanvasRenderingContext2D
  x: number
  y: number
  width: number
  height: number
  title: string
  titleX: number
  isForward: boolean
  color: string
}

const dbs = {
  'SL4.0': [
    { value: 'CDS_ITAG4.1', text: 'CDS (ITAG4.1)' },
    { value: 'cDNA_ITAG4.1', text: 'cDNA (ITAG4.1)' },
    { value: 'protein_ITAG4.1', text: 'protein (ITAG4.1)' },
    { value: 'CDS_ITAG4.0', text: 'CDS (ITAG4.0)' },
    { value: 'cDNA_ITAG4.0', text: 'cDNA (ITAG4.0)' },
    { value: 'protein_ITAG4.0', text: 'protein (ITAG4.0)' },
    { value: 'chromosome_SL4.0', text: 'chromosome (SL4.0)' }
  ],
  'SL3.0': [
    { value: 'CDS_ITAG3.2', text: 'CDS (ITAG3.2)' },
    { value: 'cDNA_ITAG3.2', text: 'cDNA (ITAG3.2)' },
    { value: 'protein_ITAG3.2', text: 'protein (ITAG3.2)' },
    { value: 'CDS_ITAG3.1', text: 'CDS (ITAG3.1)' },
    { value: 'cDNA_ITAG3.1', text: 'cDNA (ITAG3.1)' },
    { value: 'protein_ITAG3.1', text: 'protein (ITAG3.1)' },
    { value: 'CDS_ITAG3.0', text: 'CDS (ITAG3.0)' },
    { value: 'cDNA_ITAG3.0', text: 'cDNA (ITAG3.0)' },
    { value: 'protein_ITAG3.0', text: 'protein (ITAG3.0)' },
    { value: 'chromosome_SL3.0', text: 'chromosome (SL3.0)' }
  ],
  'SL2.5': [
    { value: 'CDS_ITAG2.4', text: 'CDS (ITAG2.4)' },
    { value: 'cDNA_ITAG2.4', text: 'cDNA (ITAG2.4)' },
    { value: 'protein_ITAG2.4', text: 'protein (ITAG2.4)' },
    { value: 'chromosome_SL2.5', text: 'chromosome (SL2.5)' }
  ],
  'SL2.4': [
    { value: 'CDS_ITAG2.3', text: 'CDS (ITAG2.3)' },
    { value: 'cDNA_ITAG2.3', text: 'cDNA (ITAG2.3)' },
    { value: 'protein_ITAG2.3', text: 'protein (ITAG2.3)' },
    { value: 'chromosome_SL2.4', text: 'chromosome (SL2.4)' }
  ]
}

const columns: ColumnsType<blastType.ITableColumn> = [
  {
    title: 'Subject Id',
    dataIndex: 'subjectId',
    key: 'subjectId',
    ellipsis: true,
    render: (_, record) => (
      <a
        onClick={() => {
          Modal.info({
            title: (
              <h2>
                {record.queryId} / {record.subjectId}
              </h2>
            ),
            content: <pre>{record.blastSeq}</pre>,
            width: 'fit-content',
            closable: true
          })
        }}
      >
        {record.subjectId}
      </a>
    ),
    width: 200
  },
  {
    title: 'Identity %',
    dataIndex: 'identity',
    key: 'identity',
    sorter: (itemA, itemB) => itemB.identity - itemA.identity,
    width: 150
  },
  {
    title: 'Aln',
    dataIndex: 'alignmentLen',
    key: 'alignmentLen',
    render: (_, record) =>
      `${record.alignmentLen - record.mismatches - record.gapOpens}/${
        record.alignmentLen
      }`,
    width: 150
  },
  {
    title: 'E-value',
    dataIndex: 'eValue',
    key: 'eValue',
    sorter: (itemA, itemB) => itemB.eValue - itemA.eValue,
    width: 150
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    sorter: (itemA, itemB) => itemB.score - itemA.score,
    width: 150
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    className: styles.description
  }
]

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const Blast = () => {
  const { Option } = Select
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const [category, setCategory] = useState<blastType.Category>('SL4.0')
  const [database, setDatabase] = useState<blastType.DbType>('CDS_ITAG4.1')
  const [program, setProgram] = useState<blastType.Program>('blastn')
  const [eValue, setEValue] = useState('1e-10')
  const [wordSize, setWordSize] = useState(11)
  const [seq, setSeq] = useState('')
  const [isLoading, setIsloading] = useState(false)
  const [tableData, setTableData] = useState<blastType.ITableColumn[]>()
  const [canvasItemsData, setCanvasItemData] = useState<
    { x: number; y1: number; y2: number }[]
  >([])
  const [mouseOnBlastSeq, setMouseOnBlastSeq] = useState('')

  const handleExampleClick = () => {
    setSeq(
      '>Solyc01g005710.2.1\nATGGATTGTGTAAAACTTGTGTTTTTCATGCTATATACCTTTCTCTGTCAACTTGCTTTCTCCTCATCCTCACCTCATTTATGCCCGATAGATCAAGCTCTTTCTCTTCTACAATTCAAGAACATGTTCAACATTAATCCTAATGCTTCTGATTATTGTTACGACATAAGTACAGGCGTAGAGATTCAGTCATATCCAAGAACTCTTTTCTGGAACAAGAGTACAGATTGTTGCTCATGGGATGGAGTTCACTGTGACAAGATGATAGGACTAGTGATTGAGCTTGATCTCCGTTGCAGCCAACTTCAAGGCAAGTTTCATTCCAATGGTAGCCTCTTTCAACTCTTCAATCTCAAAAGGCTTGATTTGTCTTTTAATAATTTCACCGGATCACTCATTTCACCTAAATTTGGTGAATTCTCTAGTTTGATGCATCTTGATTTGTTGGATTCAGGTTTTACAGGTATAATCCCTTCCGAAATTTGTCACCTTTCTAAACTACGTTCTGCGTATCTCGACTGA'
    )
  }

  const handleEValueChange = (v: string) => {
    console.log(v)
    setEValue(v)
  }

  const handleSeqChange = (s: string) => {
    setSeq(s)
  }

  const checkisMissParams = () => {
    let isMissParam = false
    // 检查 E-value
    if (!eValue) {
      message.error('E-value')
      isMissParam = true
    }
    if (
      !/^\d?e-\d+$/.test(eValue) &&
      !/^\d?e-\d+$/.test((+eValue).toExponential(0))
    ) {
      message.error('E-value不合格')
      isMissParam = true
    }
    if (!wordSize) {
      message.error('word-size')
      isMissParam = true
    }
    if (!seq) {
      message.error('Input')
      isMissParam = true
    }
    return isMissParam
  }

  const handleSubmitBtnClick = async () => {
    if (isLoading) {
      message.destroy('isLoading')
      message.info({
        content: '正在处理，请稍等',
        duration: 1,
        key: 'isLoading'
      })
      return
    }
    setTableData(undefined)
    setMouseOnBlastSeq('')
    setIsloading(true)
    const isMissParam = checkisMissParams()
    if (isMissParam) {
      setIsloading(false)
      return
    }
    try {
      const res = await blastService.blastSeq({
        db: database,
        program,
        eValue: (+eValue).toExponential(0).toString(),
        seq,
        wordSize
      })

      setIsloading(false)
      if (res.stat === 'ok') {
        setTableData(res.data.tableData)
      } else {
        message.error(res.msg)
      }
    } catch {
      message.error('网页暂时出现了问题')
      setIsloading(false)
    }
  }

  const handleCanvasClick = (x: number, y: number) => {
    if (!canvasItemsData.length) {
      return null
    }
    const leftBorder = 20
    const rightBorder = 980
    const topBorder = canvasItemsData[0].y1
    const tbottomBorder = canvasItemsData[canvasItemsData.length - 1].y2

    // 不在可点击范围内
    if (
      x < leftBorder ||
      x > rightBorder ||
      y < topBorder ||
      y > tbottomBorder
    ) {
      return null
    }

    const index = canvasItemsData.findIndex(
      item => y >= item.y1 && y <= item.y2
    )

    Modal.info({
      title: (
        <h2>
          {tableData![index].queryId} / {tableData![index].subjectId}
        </h2>
      ),
      content: <pre>{tableData![index].blastSeq}</pre>,
      width: 'fit-content',
      closable: true
    })
  }

  const handleCanvasMouseMove = (x: number, y: number) => {
    if (!canvasItemsData.length) {
      return null
    }
    const leftBorder = 20
    const rightBorder = 980
    const topBorder = canvasItemsData[0].y1
    const tbottomBorder = canvasItemsData[canvasItemsData.length - 1].y2

    // 不在可点击范围内
    if (
      x < leftBorder ||
      x > rightBorder ||
      y < topBorder ||
      y > tbottomBorder
    ) {
      return null
    }

    const index = canvasItemsData.findIndex(
      item => y >= item.y1 && y <= item.y2
    )

    setMouseOnBlastSeq(
      tableData![index].blastSeq
        .slice(0, tableData![index].blastSeq.indexOf('Score'))
        .trim()
    )
  }

  // canvas 画图函数 序列比对图
  const canvasFill = (params: CanvasFuncParams) => {
    const { ctx, x, y, height, title, isForward, color, titleX } = params
    const width = params.width - 10

    ctx.fillStyle = 'black'
    ctx.font = `${height}px Arial`
    ctx.textAlign = 'end'
    ctx.textBaseline = 'middle'
    ctx.fillText(title, titleX, y + 0.5 * height + 2)

    ctx.beginPath()
    ctx.fillStyle = color

    ctx.moveTo(x, y)
    // 顺序不可乱
    // 判断朝向
    if (isForward) {
      // >> 从左上角画起
      ctx.lineTo(x + width, y)
      ctx.lineTo(x + width + 10, y + 0.5 * height)
      ctx.lineTo(x + width, y + height)
      ctx.lineTo(x, y + height)
      ctx.lineTo(x + 10, y + 0.5 * height)
      ctx.lineTo(x, y)
      ctx.fill()
    } else {
      // << 从右上角开始画起
      ctx.lineTo(x - width, y)
      ctx.lineTo(x - width - 10, y + 0.5 * height)
      ctx.lineTo(x - width, y + height)
      ctx.lineTo(x, y + height)
      ctx.lineTo(x - 10, y + 0.5 * height)
      ctx.lineTo(x, y)
      ctx.fill()
    }
  }

  useEffect(() => {
    setDatabase(dbs[category][0].value as blastType.DbType)
  }, [category])

  useEffect(() => {
    if (/(tblastn|blastp|blastx)/.test(program)) {
      setWordSize(7)
    }
  }, [program])

  // 有table数据之后进行画图
  useEffect(() => {
    if (tableData) {
      const ctx = canvasRef.current!.getContext('2d')!
      ctx.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height)
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvasRef.current!.width, canvasRef.current!.height)

      const titleX = 190
      const height = 18
      const gap = 8
      // margin top
      const mt = 60
      const mr = 40
      // text margin left
      const tml = 10
      const canvasWidth = canvasRef.current!.width - titleX - 2 * mr - tml
      const colors = ['green', 'blue', 'pink', 'skyblue', 'red']

      const maxLen =
        Math.ceil(
          Math.max(
            ...tableData.map(item =>
              item.sStart > item.sEnd ? item.sStart : item.sEnd
            )
          ) / 100
        ) * 100

      // 画一条横线
      ctx.beginPath()
      ctx.strokeStyle = 'black'
      ctx.lineWidth = 1
      ctx.moveTo(titleX + tml, mt - 15)
      ctx.lineTo(canvasRef.current!.width - 1.5 * mr, mt - 15)
      ctx.stroke()

      // 设置当前字体
      ctx.fillStyle = 'black'
      ctx.font = '14px Arial'
      ctx.textAlign = 'center'
      ctx.beginPath()
      // ctx.textBaseline = 'middle'
      ctx.fillText('Id %', canvasRef.current!.width - 0.5 * mr, mt - 15)
      ctx.fill()

      // 画刻度尺
      for (let i = 0, cell = canvasWidth / 20; i <= 20; i++) {
        ctx.beginPath()
        // ctx.textBaseline = 'middle'
        ctx.fillText(
          `${i === 0 ? 'Query' : (i * maxLen) / 20}`,
          titleX + tml + i * cell,
          mt - 25
        )
        ctx.fill()

        ctx.beginPath()
        ctx.moveTo(titleX + tml + i * cell, mt - 15)
        ctx.lineTo(titleX + tml + i * cell, mt - 10)
        ctx.stroke()
      }
      const canvasItems: { x: number; y1: number; y2: number }[] = []
      // 画每个blast数据的显示条
      tableData.forEach((rowItem, index) => {
        const width =
          ((rowItem.alignmentLen - rowItem.mismatches - rowItem.gapOpens) /
            maxLen) *
          canvasWidth
        const x = rowItem.sStart + titleX + tml
        const y = index * (height + gap) + mt
        let color: string = 'red'
        switch (true) {
          case rowItem.score >= 200:
            color = colors[4]
            break
          case rowItem.score >= 80:
            color = colors[3]
            break
          case rowItem.score >= 50:
            color = colors[2]
            break
          case rowItem.score >= 40:
            color = colors[1]
            break
          default:
            color = colors[0]
        }

        // 灰白区分
        if (index % 2 === 0) {
          ctx.fillStyle = '#CBCBCB'
          ctx.fillRect(
            20,
            y - 0.5 * gap,
            canvasRef.current!.width - 2 * mr,
            height + gap
          )
        }

        // 长方形的左边角的坐标
        canvasItems.push({
          x: 20,
          y1: y - 0.5 * gap,
          y2: y - 0.5 * gap + height + gap
        })

        canvasFill({
          ctx,
          x,
          y,
          width,
          height: height,
          title: rowItem.subjectId,
          titleX,
          isForward: rowItem.sStart < rowItem.sEnd,
          color
        })

        // 不能被覆盖
        ctx.beginPath()
        ctx.fillStyle = 'black'
        ctx.font = '14px Arial'
        ctx.fillText(
          rowItem.identity + '',
          canvasRef.current!.width,
          y + 0.5 * height
        )
        ctx.fill()
      })

      setCanvasItemData(canvasItems)

      // 底部
      // 图例
      const startX = titleX + tml
      const startY = tableData.length * (height + gap) + mt + 15
      const legendWidth = 20
      canvasFill({
        ctx,
        x: startX + 5,
        y: startY,
        width: legendWidth,
        height: 14,
        title: 'Fwd：',
        isForward: true,
        color: 'black',
        titleX: startX
      })
      canvasFill({
        ctx,
        x: startX + 5 + legendWidth + 100,
        y: startY,
        width: legendWidth,
        height: 14,
        title: 'Rev：',
        isForward: false,
        color: 'black',
        titleX: startX + 100
      })

      // score
      // 设置当前字体
      ctx.fillStyle = 'black'
      ctx.font = '14px Arial'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.strokeStyle = 'black'
      ctx.lineWidth = 1

      ctx.beginPath()
      ctx.fillText(
        'Score：',
        startX + 5 + legendWidth * 2 + 100 + 50,
        startY + 7
      )
      ctx.fill()

      // 方块
      const scoreWidth = 70
      const scoreHeight = 20
      for (let i = 0; i < 5; i++) {
        ctx.fillStyle = colors[i]
        ctx.font = '14px Arial'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'

        ctx.fillRect(
          startX + 5 + legendWidth * 2 + 100 + 80 + scoreWidth * i,
          startY - 5,
          scoreWidth,
          scoreHeight
        )
        ctx.fillStyle = 'white'
        ctx.beginPath()
        let scoreTitle = ''
        switch (i) {
          case 0:
            scoreTitle = '< 40'
            break
          case 1:
            scoreTitle = '40-50'
            break
          case 2:
            scoreTitle = '50-80'
            break
          case 3:
            scoreTitle = '80-200'
            break
          case 4:
            scoreTitle = '> 200'
            break
          default:
            break
        }
        ctx.fillText(
          scoreTitle,
          startX + 5 + legendWidth * 2 + 100 + 50 + scoreWidth * (i + 1),
          startY + 7
        )
        ctx.fill()
      }
    }
  }, [tableData])

  return (
    <div className={styles.container}>
      <h1>BLAST</h1>
      <p></p>
      <div className={styles.content}>
        <TVDInputContainer
          {...CONFIG}
          title="Category"
          component={
            <Select value={category} onChange={v => setCategory(v)}>
              <Option value="SL4.0">Tomato Genome (SL4.0)</Option>
              <Option value="SL3.0">Tomato Genome (SL3.0)</Option>
              <Option value="SL2.5">Tomato Genome (SL2.5)</Option>
              <Option value="SL2.4">Tomato Genome (SL2.4)</Option>
            </Select>
          }
        />
        <TVDInputContainer
          {...CONFIG}
          title="Database"
          component={
            <Select value={database} onChange={v => setDatabase(v)}>
              {dbs[category].map(item => (
                <Option key={item.value} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          }
        />
        <TVDInputContainer
          {...CONFIG}
          title="Program"
          component={
            <Select value={program} onChange={v => setProgram(v)}>
              <Option value="blastn">
                blastn (nucleotide to nucleotide db)
              </Option>
              <Option value="blastp">blastp (protein to protein db)</Option>
              <Option value="blastx">
                blastx (translated nucleotide to protein db)
              </Option>
              <Option value="tblastn">
                tblastn (protein to translated nucleotide db)
              </Option>
              <Option value="tblastx">
                tblastx (translated nucleotide to translated nucleotide db)
              </Option>
            </Select>
          }
        />
        <TVDInputContainer
          {...CONFIG}
          title="E-value"
          component={
            <Input
              value={eValue}
              onChange={e => handleEValueChange(e.target.value)}
            />
          }
        />

        <TVDInputContainer
          {...CONFIG}
          title="Word Size"
          component={
            <InputNumber
              value={wordSize}
              min={5}
              max={/(tblastn|blastp|blastx)/.test(program) ? 8 : 16}
              onChange={s => setWordSize(s)}
            />
          }
        />
        <TVDTextarea
          {...CONFIG}
          title="Input"
          placeholder="Please input Sequence"
          example="Try Example"
          allowClear={true}
          value={seq}
          height={200}
          onChange={v => setSeq(v)}
          setExample={handleExampleClick}
        />

        <div className={styles.btn_contaienr}>
          <Button
            type="primary"
            loading={isLoading}
            onClick={handleSubmitBtnClick}
          >
            Submit
          </Button>
        </div>
      </div>

      <div>
        {tableData && (
          <>
            {mouseOnBlastSeq && (
              <Anchor>
                <p style={{ position: 'sticky', backgroundColor: 'white' }}>
                  Symbols: {mouseOnBlastSeq}
                </p>
              </Anchor>
            )}
            <canvas
              ref={canvasRef}
              width={1000}
              height={tableData.length * 26 + 120}
              className={styles.canvas}
              onClick={e => {
                handleCanvasClick(
                  e.clientX - canvasRef.current!.getBoundingClientRect().left,
                  e.clientY - canvasRef.current!.getBoundingClientRect().top
                )
              }}
              onMouseMove={e => {
                handleCanvasMouseMove(
                  e.clientX - canvasRef.current!.getBoundingClientRect().left,
                  e.clientY - canvasRef.current!.getBoundingClientRect().top
                )
              }}
            >
              您的浏览器不支持 HTML5 canvas 标签。
            </canvas>

            <Table
              className={styles.table}
              size="small"
              columns={columns}
              rowKey="id"
              dataSource={tableData}
              pagination={{
                position: ['topRight', 'bottomRight']
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Blast
