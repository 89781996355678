import homeImg1 from './home/home_img1.png'
import homeImg2 from './home/home_img2.png'
import homeImg3 from './home/home_img3.png'
import homeImg4 from './home/home_img4.png'

import accessionImg from './home/accession.jpg'
import phenotypeImg from './home/phenotype.jpg'
import popStructureImg from './home/pop_structure.png'

import pangenomeImg from './home/pangenome.png'
import pavImg from './home/pav.jpg'
import pantranImg from './home/pantran.png'

import variationImg from './home/variation.jpg'
import gwasImg from './home/gwas.png'
import eqtlImg from './home/eqtl.png'
import sqtlImg from './home/sqtl.png'
import geneImg from './home/gene.png'
import expressionImg from './home/expression.jpg'
import selectionImg from './home/selection.png'
import introgressionImg from './home/introgression.png'

import converterImg from './home/converter.png'
import blastImg from './home/blast.jpg'
import genomeImg from './home/genome.jpg'
import haplotypeImg from './home/haplotype.png'
import primerImg from './home/primer.png'

import loadingGif from './imgs/loading.gif'
import CRGV from './imgs/CRGV(`C]DB3O3[FRTX{UFXI.jpg'

const moduleImgs = {
  accessionImg,
  phenotypeImg,
  popStructureImg,
  variationImg,
  gwasImg,
  eqtlImg,
  sqtlImg,
  geneImg,
  expressionImg,
  selectionImg,
  introgressionImg,
  pangenomeImg,
  pavImg,
  pantranImg,
  converterImg,
  blastImg,
  genomeImg,
  haplotypeImg,
  primerImg
}

export {
  homeImg1, homeImg2, homeImg3, homeImg4,
  moduleImgs,
  loadingGif,
  CRGV
}
