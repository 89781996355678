import { useEffect, useState } from 'react'
import { Button, Input, Radio, Space } from 'antd'
import styles from './style.module.less'

export type Model = 'id' | 'region' | 'sequence'

export interface GeneralParams {
  model: Model
  general: string
}

interface Props {
  setParams: (params: GeneralParams) => void
}

const seqE =
  '>Solyc01g010810.2.1\nATGGCTGTTGATTCTACTGCTGAGCGTCCTCTTTTCGGTGGTGCTATTTCCACTACTTTCCCTATCCGATTCCAGGATGTTAGTAATGTCCGTCAAGTTCCTGATCATCAGGAGGCATTTGTGGACCCTGAGCGCGATGAGAGTTTGATAATTGAGCTTCTGGATCTGAAGATGGATGTAGCAGACAGTGGAAGTGCCACTTGGTTTCTTCAAGACCTTGCCAATGAACAAGAGGCTGAGGGAGCTACAATCACTGAGCAGTCAGCAGTGTTTGAGGCCCCTGGATTGTGCTATAGAAACATGCCTGCTGTCATCACCACCGCTGTTGGTCAGATGGCAGTTGCCAAGGGAAAACAAGGGAGGGAAGCACAGAACTTGGTTAAGGTGTACCTGGCAAACATTCGCCTTAAGGAAGTTGGGACAGATGTACTGATAACTGCATATGAGCCTCTAGTAATTAACCCCTTGAGTGAGAGTGCTACCGCAGTTGGGGCAGGCATGGCTGTACCTGCTGCACAGTCTGGAGTTATGCCAATGGCTGAAGTTTTTAAACTTGCAGTCTCTAGTTTCAAAGTGCACAACTGGAGTCTCTTTAGCTCTGCTGCTGCTTGA'
const idE = 'Solyc01g010810.2.1'
const regionE = 'SL4.0ch03:200-1900'

const PDGeneral = (props: Props) => {
  const [general, setGeneral] = useState('')
  const [model, setModel] = useState<Model>('sequence')

  const handleExampleBtnClick = () => {
    setGeneral(model === 'sequence' ? seqE : model === 'region' ? regionE : idE)
  }

  const handleChange = (v: string) => {
    setGeneral(v)
  }

  useEffect(() => {
    props.setParams({ model, general })
  }, [general, model])

  return (
    <div className={styles.general_container}>
      <h2>General Primer Design</h2>
      <div className={styles.vertical}>
        <Input.TextArea
          placeholder={
            model === 'sequence' ? seqE : model === 'region' ? regionE : idE
          }
          className={styles.general_textarea}
          value={general}
          onChange={e => handleChange(e.target.value)}
        />
        <Radio.Group
          style={{ marginLeft: '20px' }}
          value={model}
          onChange={e => setModel(e.target.value)}
        >
          <Space direction="vertical">
            <Radio value={'id'}>ID</Radio>
            <Radio value={'region'}>Region</Radio>
            <Radio value={'sequence'}>Sequence</Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className={styles.general_btns}>
        <Button onClick={handleExampleBtnClick}>Example</Button>
        <Button onClick={() => setGeneral('')}>Clear</Button>
      </div>

      <div></div>
    </div>
  )
}

export default PDGeneral
