import request, { BaseRes } from '@/libs/request'
import { accessionType } from '../types'

const PREFIX = '/api/accession'

export const getAccessData = async (body: accessionType.IAIBody) => {
  const { data } = await request.post<BaseRes<accessionType.IAIRes>>(PREFIX + '/accessInfo', body)
  return data
}

