import statList from '../libs/stat'

export const downloadFile = (content: string, fileName: string) => {
  const a = document.createElement('a')
  a.href = content
  a.download = fileName
  a.click()
}

export const checkParams = (params: { [key: string]: any }) => {
  for (const key of Object.keys(params)) {
    if (!params[key]) {
      return true
    }
  }
  return false
}

export const checkStat = (stat: string) => {
  const keys = Object.keys(statList)
  return keys.includes(stat)
}

export const sleep = (ms: number) => {
  const now = new Date().getTime()
  while (true) {
    if (new Date().getTime() - now < ms) break
  }
}



// 节流函数
export const throttle = (callback: Function, time: number) => {
  let timer: number = 0
  return (...args: any) => {
    // console.log(timer)
    if (!timer) {
      timer = window.setTimeout(() => {
        timer = 0
        callback(args)
      }, time)
    }
  }
}

export const debounce = (callback: Function, time: number) => {
  let timer: number = 0
  return (...args: any) => {
    if (timer) {
      clearTimeout(timer)
      timer = 0 
    }
    timer = window.setTimeout(() => {
      timer = 0
      callback(args)
    }, time)
  }
}
