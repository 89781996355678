import Home from '..//Home'
import { Accession, Phenotype, Population } from '../Accession'
import { Variation, GWAS, EQTL, SQTL, Gene, Expression, SelectionSignals, Introgression, Pan } from '../Search'
import { Genome, Transcriptome, PAV } from '../Pangenome'
import { Blast, Converter, GenomeBrowser, HaplotypeNetworkAnalysis, PrimerDesign } from '../Tools'
import Download from '../Download'
import Tutorial from '../Tutorial'
import Contact from '../Contact'

const routes = [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '/accession',
    exact: true,
    component: Accession
  },
  {
    path: '/phenotype',
    exact: true,
    component: Phenotype
  },
  {
    path: '/population',
    exact: true,
    component: Population
  },
  {
    path: '/search/variation',
    exact: true,
    component: Variation
  },
  {
    path: '/search/gwas',
    exact: true,
    component: GWAS
  },
  {
    path: '/search/eqtl',
    exact: true,
    component: EQTL
  },
  {
    path: '/search/sqtl',
    exact: true,
    component: SQTL
  },
  {
    path: '/search/gene',
    exact: true,
    component: Gene
  },
  {
    path: '/search/expression',
    exact: true,
    component: Expression
  },
  {
    path: '/search/selection',
    exact: true,
    component: SelectionSignals
  },
  {
    path: '/search/introgression',
    exact: true,
    component: Introgression
  },
  {
    path: '/search/pan',
    exact: true,
    component: Pan
  },
  {
    path: '/pan/genome',
    exact: true,
    component: Genome
  },
  {
    path: '/pan/transcriptome',
    exact: true,
    component: Transcriptome
  },
  {
    path: '/pan/pav',
    exact: true,
    component: PAV
  },
  {
    path: '/tools/converter',
    exact: true,
    component: Converter
  },
  {
    path: '/tools/blast',
    exact: true,
    component: Blast
  },
  // {
  //   path: '/tools/browser',
  //   exact: true,
  //   component: GenomeBrowser
  // },
  {
    path: '/tools/analysis',
    exact: true,
    component: HaplotypeNetworkAnalysis
  },
  {
    path: '/tools/primer',
    exact: true,
    component: PrimerDesign
  },
  {
    path: '/download',
    exact: true,
    component: Download
  },
  {
    path: '/tutorial',
    exact: true,
    component: Tutorial
  },
  {
    path: '/contact',
    exact: true,
    component: Contact
  }
]

export default routes
