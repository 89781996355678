import { useState, useEffect } from 'react'
import { Input, Table, message } from 'antd'
import styles from './style.module.less'
import { accessionService } from '@/services'
import { accessionType } from '@/types'

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    key: 'index',
    width: 45
  },
  {
    title: 'Accession',
    dataIndex: 'accession',
    key: 'accession',
    width: 100
  },
  {
    title: 'Group',
    dataIndex: 'group',
    key: 'group',
    width: 70
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    key: 'categories'
  },
  {
    title: 'Taxon',
    dataIndex: 'taxon',
    key: 'taxon',
    width: 150
  },
  {
    title: 'Other ID',
    dataIndex: 'otherID',
    key: 'otherID'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: 90
  },
  {
    title: 'Province',
    dataIndex: 'province',
    key: 'province'
  },
  {
    title: 'SRA_acc.',
    dataIndex: 'sraAcc',
    key: 'sraAcc'
  },
  {
    title: 'Reads (Mb)',
    dataIndex: 'cleanReads',
    key: 'cleanReads',
    width: 105
  },
  {
    title: 'Alignment',
    dataIndex: 'alignment',
    key: 'alignment',
    width: 95
  },
  {
    title: 'Coverage',
    dataIndex: 'coverage',
    key: 'coverage',
    width: 90
  },
  {
    title: 'Depth(X)',
    dataIndex: 'depth',
    key: 'depth',
    width: 85
  }
]

const Accession = () => {
  const { Search } = Input
  const [data, setData] = useState<accessionType.IPAIItem[]>([])
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(10)
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState<string>()

  const getData = async () => {
    if (keyword?.length && /[?!><'"@#$%^&*()+[\]？》《‘“】【{}/]/.test(keyword)) {
      message.error('搜索内容包含无效字符')
      return
    }
    setLoading(true)
    const res = await accessionService.getAccessData({
      start: (current - 1) * limit,
      limit,
      keyword
    })
    setData(res.data.rows)
    total !== res.data.total && setTotal(res.data.total)
    setLoading(false)
  }

  useEffect(() => {
    setCurrent(1)
    setData([])
  }, [limit])

  useEffect(() => {
    getData()
  }, [current, limit])

  return (
    <div className={styles.container}>
      <h1>Accession</h1>
      <p></p>
      <div className={styles.content}>
        <div className={styles.tableBtn}>
          <Search
            placeholder="The first 9 columns of information are available for search"
            allowClear
            disabled={loading}
            enterButton="Search"
            size="large"
            onChange={e => setKeyword(e.target.value.trim())}
            onSearch={() => {
              getData()
              setCurrent(1)
            }}
          />
        </div>
        <Table
          className={styles.table}
          columns={columns}
          dataSource={data}
          loading={loading}
          size="small"
          rowKey="accession"
          bordered={true}
          pagination={{
            total,
            current: current,
            pageSize: limit,
            position: ['topRight', 'bottomRight']
          }}
          onChange={pagination => {
            setCurrent(pagination.current as number)
            setLimit(pagination.pageSize as number)
          }}
          sticky
        />
      </div>
    </div>
  )
}

export default Accession
