import { useState } from 'react'
import { Select, message, InputNumber, Input, Button } from 'antd'
import {
  TVDChromosomeSelect,
  TVDInputContainer,
  TVDMulSelect,
  TVDTextarea
} from '@/components'
import styles from './style.module.less'
import { commonType, gwasType } from '@/types'

const popOptions = ['SLL', 'SLC', 'SP', 'WILD']

const chrOptions: commonType.Chromosome[] = [
  'ch00',
  'ch01',
  'ch02',
  'ch03',
  'ch04',
  'ch05',
  'ch06',
  'ch07',
  'ch08',
  'ch09',
  'ch10',
  'ch11',
  'ch12'
]

const CONFIG = {
  width: 520 + 200,
  titlewidth: 200,
  textareawidth: 520
}

const GenomeCoordinateConverter = () => {
  const { Option } = Select

  const [inputVersion, setInputVersion] = useState<string>('SL4.0')
  const [chromosome, setChromosome] = useState<gwasType.Chromosome>('ch00')
  const [outputVersion, setOutputVersion] = useState<string>('SL4.0')
  const [start, setStart] = useState<number>()
  const [end, setEnd] = useState<number>()

  return (
    <div className={styles.container}>
      <h1>Genome Coordinate Converter</h1>
      <p></p>
      <div className={styles.content}>
        <TVDInputContainer
          {...CONFIG}
          title="Input Genome Version"
          component={
            <Select value={inputVersion} onChange={v => setInputVersion(v)}>
              <Option value="SL4.0">Tomato Genome (SL4.0)</Option>
              <Option value="SL3.0">Tomato Genome (SL3.0)</Option>
              <Option value="SL2.5">Tomato Genome (SL2.5)</Option>
              <Option value="SL2.4">Tomato Genome (SL2.4)</Option>
            </Select>
          }
        />

        <TVDChromosomeSelect
          {...CONFIG}
          options={chrOptions}
          value={chromosome}
          prefix={false}
          onChange={v => setChromosome(v)}
        />

        <TVDInputContainer
          {...CONFIG}
          title="Input Genome Region"
          component={
            <Input.Group compact>
              <span className={styles.input_group_span}>Start</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Start Position"
                min={0}
                max={end}
                value={start}
                onChange={start => setStart(start)}
              />
              <span className={styles.input_group_span}>End</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="End Position"
                min={start ?? 0}
                value={end}
                onChange={end => setEnd(end)}
              />
            </Input.Group>
          }
          afterComponent={
            <span>
              <span>
                eg:{' '}
                <span
                  className="after_eg"
                  onClick={() => {
                    setStart(2000)
                    setEnd(20000)
                  }}
                >
                  2000-20000
                </span>
                , input a range (&lt; 100 kb)
              </span>
            </span>
          }
        />

        <TVDInputContainer
          {...CONFIG}
          title="OutPut Genome Version"
          component={
            <Select value={outputVersion} onChange={v => setOutputVersion(v)}>
              <Option value="SL4.0">Tomato Genome (SL4.0)</Option>
              <Option value="SL3.0">Tomato Genome (SL3.0)</Option>
              <Option value="SL2.5">Tomato Genome (SL2.5)</Option>
              <Option value="SL2.4">Tomato Genome (SL2.4)</Option>
            </Select>
          }
        />

        <div className={styles.btn_contaienr}>
          <Button
            type="primary"
            onClick={() => {
              message.info('你点击了提交按钮')
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GenomeCoordinateConverter
