import { useEffect, useState } from 'react'
import { Input, InputNumber, message, Radio, Select } from 'antd'
import { TVDInputContainer } from '@/components'
import styles from './style.module.less'

type Type = 'Population' | 'Individual'

export interface VaraIdParams {}

interface Props {
  setParams: (params: VaraIdParams) => void
}

const PDVariation = (props: Props) => {
  const { Option } = Select
  const [refGenome, setRefGenome] = useState('SL4.0')
  const [variationType, setVariationType] = useState('SNP')
  const [type, setType] = useState<Type>('Individual')
  const [variationId, setVariationId] = useState('')
  const [population1, setPopulation1] = useState('')
  const [population2, setPopulation2] = useState('')
  const [accession1, setAccession1] = useState('')
  const [accession2, setAccession2] = useState('')
  const [upstream, setUpstream] = useState('')
  const [downstream, setDownstream] = useState('')
  const [fst, setFst] = useState('0.8')

  useEffect(() => {
    props.setParams({
      type,
      variationId,
      population1,
      population2,
      accession1,
      accession2,
      upstream,
      downstream
    })
  }, [
    type,
    variationId,
    population1,
    population2,
    accession1,
    accession2,
    upstream,
    downstream
  ])

  return (
    <div className={styles.variation_container}>
      <h2>Primer Design by Variation ID</h2>
      <div className={styles.variation_content}>
        {/* <TVDInputContainer
          type="PDpro"
          title="Reference Genome"
          component={
            <Radio.Group
              value={refGenome}
              onChange={e => setRefGenome(e.target.value)}
            >
              <Radio value={'Pan-genome'}>Pan-genome</Radio>
              <Radio value={'SL4.0'}>SL4.0</Radio>
              <Radio value={'SL3.0'}>SL3.0</Radio>
              <Radio value={'SL2.50'}>SL2.50</Radio>
              <Radio value={'SL2.40'}>SL2.40</Radio>
            </Radio.Group>
          }
        />
        <TVDInputContainer
          type="PD"
          title="Variation Type"
          component={
            <Radio.Group
              value={variationType}
              onChange={e => setVariationType(e.target.value)}
            >
              <Radio value={'SNP'}>SNP</Radio>
              <Radio value={'InDel'}>InDel</Radio>
              <Radio value={'CNV'}>CNV</Radio>
              <Radio value={'SV'}>SV</Radio>
              <Radio value={'Total'}>Total</Radio>
            </Radio.Group>
          }
        /> */}
        <TVDInputContainer
          type="PD"
          title="Variation ID"
          classname={styles.firstChild}
          component={
            <Input
              value={variationId}
              onChange={e => setVariationId(e.target.value)}
              style={{ width: '400px' }}
            />
          }
        />

        <TVDInputContainer
          type="PDpro"
          title="Flanks (bp)"
          component={
            <Input.Group compact>
              <span className={styles.input_group_span}>Upstream</span>
              <Input
                className={styles.groupInputString}
                value={upstream}
                onChange={e => setUpstream(e.target.value)}
              />
              <span className={styles.input_group_span}>Downstream</span>
              <Input
                className={styles.groupInputString}
                value={upstream}
                onChange={e => setUpstream(e.target.value)}
              />
            </Input.Group>
          }
        />
        <TVDInputContainer
          type="PDpro"
          title={
            <Select
              className={styles.titleComponent}
              value={type}
              onChange={(v: Type) => setType(v)}
            >
              <Option value="Individual">Individual</Option>
              <Option value="Population">Population</Option>
            </Select>
          }
          component={
            type !== 'Population' ? (
              <Input.Group compact>
                <span className={styles.input_group_span}>Accession1</span>
                <Input
                  className={styles.groupInputString}
                  value={accession1}
                  placeholder="TS1"
                  onChange={e => setAccession1(e.target.value)}
                />
                <span className={styles.input_group_span}>Accession2</span>
                <Input
                  className={styles.groupInputString}
                  value={accession2}
                  placeholder="TS13"
                  onChange={e => setAccession2(e.target.value)}
                />
              </Input.Group>
            ) : (
              <Input.Group compact>
                <span className={styles.input_group_span}>Population1</span>
                <Select
                  className={styles.groupInputString}
                  value={population1}
                  onChange={(v: string) => setPopulation1(v)}
                >
                  <Option value="TS1">TS1</Option>
                  <Option value="TS9">TS9</Option>
                  <Option value="TS18">TS18</Option>
                </Select>
                <span className={styles.input_group_span}>Population2</span>
                <Select
                  className={styles.groupInputString}
                  value={population2}
                  onChange={(v: string) => setPopulation2(v)}
                >
                  <Option value="TS1">TS1</Option>
                  <Option value="TS9">TS9</Option>
                  <Option value="TS18">TS18</Option>
                </Select>
                <span className={styles.input_group_span}>Fst</span>
                <Select
                  className={styles.groupInputString}
                  value={fst}
                  onChange={(v: string) => setFst(v)}
                >
                  <Option value="0.8">0.8</Option>
                  <Option value="0.85">0.85</Option>
                  <Option value="0.9">0.9</Option>
                  <Option value="0.95">0.95</Option>
                  <Option value="1.0">1.0</Option>
                </Select>
              </Input.Group>
            )
          }
        />
      </div>
    </div>
  )
}

export default PDVariation
