import { useState } from 'react'
import { Input, Select } from 'antd'
import { TVDInputContainer, TVDMulSelect, TVDTextarea } from '@/components'
import styles from './style.module.less'

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}
const VGene = () => {
  const [geneIds, setGeneIds] = useState('')

  return (
    <>
      <h2>Search for Variations by Gene:</h2>
      <div>
        <TVDTextarea
          {...CONFIG}
          title="Gene IDs"
          placeholder="Please input Gene IDs"
          example="Try Example"
          value={geneIds}
          allowClear={true}
          titlewidth={160}
          height={200}
          onChange={v => setGeneIds(v)}
          setExample={() =>
            setGeneIds(
              'Solyc12g100270\nSolyc12g100280\nSolyc12g100290\nSolyc12g100300\nSolyc12g100310\nSolyc12g100320\nSolyc12g100330\nSolyc12g100340\nSolyc12g100350\nSolyc12g100360'
            )
          }
        />
      </div>
    </>
  )
}

export default VGene
