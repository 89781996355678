import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { TVDInputContainer } from '..'

interface Props {
  [key: string]: any
  title?: string
  placeholder?: string
  value?: string[]
  type?: 'normal' | 'PD'
  options?: string[]
  onChange?: (v: string[]) => void
  width?: number | string
  titlewidth?: number | string
}

const populationData = ['All', 'SLL', 'SLC', 'SP', 'WILD']

const TVDMulSelect = (props: Props) => {
  const { Option } = Select
  const {
    options = [],
    title = 'Population',
    placeholder = 'Population',
    value = [options[0]],
    onChange,
    width,
    titlewidth,
    ...selectProps
  } = props

  return (
    <TVDInputContainer
      width={width}
      titlewidth={titlewidth}
      title={title}
      type={props.type}
      component={
        <Select
          placeholder={placeholder}
          optionLabelProp="label"
          {...selectProps}
          mode="multiple"
          value={value}
          onChange={vals => onChange?.(vals)}
        >
          {options.map(item => (
            <Option key={item} value={item} label={item}>
              <div className="demo-option-label-item">{item}</div>
            </Option>
          ))}
        </Select>
      }
    />
  )
}

export default TVDMulSelect
