import request, { BaseRes } from '@/libs/request'
import { expType } from '../types'

const PREFIX = '/api/exp'

export const getSamples = async () => {
  const { data } = await request.get<BaseRes<{ sample: string, type: string }[]>>(PREFIX + '/samples')
  return data
}

export const idsToHeatmap = async (body: expType.IIdsBody) => {
  const { data } = await request.post<BaseRes<expType.IRes>>(PREFIX + '/ids', body)
  return data
}

export const regionToHeatmap = async (body: expType.IRegionBody) => {
  const { data } = await request.post<BaseRes<expType.IRes>>(PREFIX + '/region', body)
  return data
}

export const getTableData = async (body: { start?: number, limit?: number, keyword?: string }) => {
  const { data } = await request.post<BaseRes<expType.ITableRes>>(PREFIX + '/tab', body)
  return data
}
