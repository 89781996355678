import { useState, useEffect, useRef } from 'react'
import AMapLoader from '@amap/amap-jsapi-loader'
import styles from './style.module.less'

const Contact = () => {
  const mapRef = useRef<HTMLDivElement>(null)

  const initMap = async () => {
    try {
      const AMap = await AMapLoader.load({
        key: '40a6a5f2bb05cd9f610ce343c50b8027', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 缺省 1.1
          plugins: [] // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '1.3.2' // Loca 版本，缺省 1.3.2
        }
      })
      const map = new AMap.Map(mapRef.current, {
        zoom: 14,
        resizeEnable: true,
        center: [114.354786, 30.471262], // 设置地图中心点坐标
        lang: 'zh_en' // 可选值：en，zh_en, zh_cn
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    initMap()
  }, [])

  return (
    <div className={styles.container}>
      {/* <h1>Contact</h1> */}
      <div className={styles.item_container}>
        <p>Contact Us</p>
        <div>
          Home Page:{' '}
          <a href="https://faculty.hzau.edu.cn/ouyangbo/zh_CN/index.htm">
            Bo Ouyang Ph.D. Professor
          </a>
          <br />
          <br />
          <div className={styles.mail}>
            <span>Email:</span>
            <a href="mailto:bouy@mail.hzau.edu.cn">bouy@mail.hzau.edu.cn</a>
            <a href="mailto:yuhuiyang1234@126.com">yuhuiyang1234@126.com</a>
            <a href="mailto:shichunmei202011@163.com">shichunmei202011@163.com</a>
            <a href="mailto:lunyiyan@foxmail.com">lunyiyan@foxmail.com</a>
          </div>
        </div>
      </div>
      <div className={styles.item_container}> 
        <p>Find Us</p>
        <div ref={mapRef} className={styles.map_container}></div>
      </div>
    </div>
  )
}

export default Contact
