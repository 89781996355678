import { ChangeEvent, useState } from 'react'
import {
  TVDChromosomeSelect,
  TVDInputContainer,
  TVDMulSelect,
  TVDTextarea
} from '@/components'
import {
  Radio,
  Input,
  message,
  Button,
  Select,
  RadioChangeEvent,
  Space,
  Tabs,
  InputNumber
} from 'antd'
import styles from './style.module.less'
import { gwasType } from '@/types'

const chromosomeOptions: gwasType.Chromosome[] = [
  'ch00',
  'ch01',
  'ch02',
  'ch03',
  'ch04',
  'ch05',
  'ch06',
  'ch07',
  'ch08',
  'ch09',
  'ch10',
  'ch11',
  'ch12'
]
const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}
const SQTL = () => {
  const { Option } = Select
  const { TabPane } = Tabs
  const [type, setType] = useState('region')
  const [start, setStart] = useState<number>()
  const [end, setEnd] = useState<number>()

  const [ids, setIds] = useState('')

  return (
    <div className={styles.container}>
      <h1>sQTL</h1>
      <p></p>
      <div className={styles.content}>
        {/* <TVDInputContainer
          title="Reference Genome"
          component={
            <Radio.Group onChange={handleValueChange}>
              <Radio value={'SL4.0'}>SL4.0</Radio>
              <Radio value={'SL3.0'}>SL3.0</Radio>
              <Radio value={'SL2.50'}>SL2.50</Radio>
              <Radio value={'SL2.40'}>SL2.40</Radio>
            </Radio.Group>
          }
        /> */}

        <Tabs
          className={styles.tabs}
          onChange={key => {
            setType(key)
          }}
          type="card"
        >
          <TabPane tab="By Region" key="region">
            <TVDChromosomeSelect options={chromosomeOptions} {...CONFIG} />

            <TVDInputContainer
              {...CONFIG}
              title={<span className={styles.title}>Region</span>}
              component={
                <Input.Group compact>
                  <span className={styles.input_group_span}>Start</span>
                  <InputNumber
                    min={0}
                    max={end}
                    value={start}
                    onChange={n => setStart(n)}
                    className={styles.groupInputNumber}
                    placeholder="Start Position"
                  />
                  <span className={styles.input_group_span}>End</span>
                  <InputNumber
                    min={start ?? 0}
                    value={end}
                    onChange={n => setEnd(n)}
                    className={styles.groupInputNumber}
                    placeholder="Start Position"
                  />
                </Input.Group>
              }
              afterComponent={
                <span>
                  <span>
                    eg:{' '}
                    <span
                      className="after_eg"
                      onClick={() => {
                        setStart(2000)
                        setEnd(20000)
                      }}
                    >
                      2000-20000
                    </span>
                    , input a range (&lt; 100 kb)
                  </span>
                </span>
              }
            />
          </TabPane>

          <TabPane tab="By Gene" key="gene">
            {/* <TVDChromosomeSelect {...CONFIG} /> */}

            <TVDTextarea
              {...CONFIG}
              title="Gene IDs"
              placeholder="Please input Gene IDs"
              example="Try Example"
              value={ids}
              allowClear={true}
              height={200}
              onChange={v => setIds(v)}
              setExample={() =>
                setIds(
                  'SL4.0ch01_3971781\nSL4.0ch01_3971917\nSL4.0ch01_3971935\nSL4.0ch01_3971958\nSL4.0ch01_3971978\nSL4.0ch01_3971993\nSL4.0ch01_3972212\nSL4.0ch01_3972229\nSL4.0ch01_3972457\nSL4.0ch01_3972477\nSL4.0ch01_3972580\nSL4.0ch01_3972599'
                )
              }
            />
          </TabPane>
        </Tabs>

        <div className={styles.btn_contaienr}>
          <Button
            type="primary"
            onClick={() => {
              message.info('你点击了提交按钮')
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SQTL
