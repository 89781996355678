import { useState } from 'react'
import { Input, InputNumber, Radio, Select } from 'antd'
import {
  TVDChromosomeSelect,
  TVDInputContainer,
  TVDMulSelect,
  TVDTextarea
} from '@/components'
import styles from './style.module.less'
import { gwasType } from '@/types'

const popOptions = ['SLL', 'SLC', 'SP', 'WILD']
const chromosomeOptions: gwasType.Chromosome[] = [
  'ch00',
  'ch01',
  'ch02',
  'ch03',
  'ch04',
  'ch05',
  'ch06',
  'ch07',
  'ch08',
  'ch09',
  'ch10',
  'ch11',
  'ch12'
]

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const VRegion = () => {
  const { Option } = Select
  const { TextArea } = Input

  const [refGenome, setRefGenome] = useState<gwasType.RefGenome>('SL4.0')
  const [chromosome, setChromosome] = useState<gwasType.Chromosome>('ch01')
  const [population, setPopulation] = useState<string[]>([
    'SLL',
    'SLC',
    'SP',
    'WILD'
  ])
  const [start, setStart] = useState<number>()
  const [end, setEnd] = useState<number>()

  const [accession, setAccession] = useState<string>()

  return (
    <>
      <h2>Search for Variations in a Region:</h2>
      {/* <TVDInputContainer
              {...CONFIG}

          title="Reference Genome"
          component={
            <Radio.Group onChange={handleValueChange}>
              <Radio value={'SL4.0'}>SL4.0</Radio>
              <Radio value={'SL3.0'}>SL3.0</Radio>
              <Radio value={'SL2.50'}>SL2.50</Radio>
              <Radio value={'SL2.40'}>SL2.40</Radio>
            </Radio.Group>
          }
        /> */}

      <TVDChromosomeSelect
        {...CONFIG}
        options={chromosomeOptions}
        value={chromosome}
        onChange={v => setChromosome(v)}
      />

      <TVDInputContainer
        {...CONFIG}
        title="Region"
        component={
          <Input.Group compact>
            <span className={styles.input_group_span}>Start</span>
            <InputNumber
              className={styles.groupInputNumber}
              placeholder="Start Position"
              min={0}
              max={end}
              value={start}
              onChange={start => setStart(start)}
            />
            <span className={styles.input_group_span}>End</span>
            <InputNumber
              className={styles.groupInputNumber}
              placeholder="End Position"
              min={start ?? 0}
              value={end}
              onChange={end => setEnd(end)}
            />
          </Input.Group>
        }
        afterComponent={
          <span>
            <span>
              eg:{' '}
              <span
                className="after_eg"
                onClick={() => {
                  setStart(2000)
                  setEnd(20000)
                }}
              >
                2000-20000
              </span>
              , input a range (&lt; 100 kb)
            </span>
          </span>
        }
      />

      <TVDInputContainer
        {...CONFIG}
        title="Annotation"
        component={
          <Select
            defaultValue="startCodon"
            onChange={(v: string) => console.log(v)}
          >
            <Option value="startCodon">Start Codon mutation</Option>
            <Option value="stopCodon">Stop Codon mutation</Option>
            <Option value="nonsynonymous">Nonsynonymous mutation</Option>
            <Option value="dynonymous">Synonymous mutation</Option>
            <Option value="intron">Intron mutation</Option>
            <Option value="UTR">UTR mutation</Option>
            <Option value="intergenicRegion">Intergenic region mutation</Option>
          </Select>
        }
      />
    </>
  )
}

export default VRegion
