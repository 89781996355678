import { useEffect, useMemo, useRef, useState } from 'react'
import { Button, message, Select, Table, Tabs, Input, Space } from 'antd'
import { traitService } from '@/services'
import { traitType } from '@/types'
import * as Plotly from 'plotly.js-dist-min'
import styles from './style.module.less'
import { downloadFile, sleep } from '@/utils'
import { ColumnsType } from 'antd/lib/table'
import { useHistory } from 'react-router-dom'

const Phenotype = () => {
  const { Search } = Input
  const { TabPane } = Tabs
  const history = useHistory()
  const divRef = useRef<HTMLDivElement>(null)
  const [category, setCategory] = useState<traitType.Key>('agronomic')
  const [agronomicData, setAgronomicData] = useState<traitType.TraitItem[]>([])
  const [metabolicData, setMetabolicData] = useState<traitType.TraitItem[]>([])
  const [stressData, setStressData] = useState<traitType.TraitItem[]>([])
  const [trait, setTrait] = useState<traitType.TraitItem>()
  const [plotData, setPlotData] = useState<traitType.TraitDataItem[]>()
  const [valTypes, setValTypes] = useState<number[]>([])
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState<traitType.TraitDataItem[]>()
  const [tabsTableData, setTabsTableData] = useState<traitType.TraitItem[]>()

  const phenomeColumns = useMemo(() => {
    return [
      ...[
        {
          title: 'No.',
          dataIndex: 'index',
          key: 'index',
          width: 60
        },
        {
          title: 'Accession',
          dataIndex: 'accession',
          key: 'accession',
          width: 120
        },
        {
          title: 'Group',
          dataIndex: 'group',
          key: 'group',
          width: 120
        },
        {
          title: 'Taxon',
          dataIndex: 'taxon',
          key: 'taxon'
        }
      ],
      ...valTypes.map((_, i) => ({
        title: `${trait?.id}${valTypes.length > 1 ? '-' + (i + 1) : ''}`,
        dataIndex: `value${i + 1}`,
        key: `value${i + 1}`
      }))
    ]
  }, [trait, valTypes])

  const drawPlot = async (
    oridata: traitType.TraitDataItem[],
    valTypes: number[],
    trait: traitType.TraitItem
  ) => {
    // 此画图函数通过DOM操作实现，向图容器节点添加图子节点
    // 删除图容器节点下的所有子节点
    const nodeList = divRef.current!.childNodes
    if (nodeList && nodeList.length) {
      for (let i = nodeList.length - 1; i >= 0; i--) {
        divRef.current!.removeChild(nodeList[i])
      }
    }

    // 画多图
    valTypes.forEach(async (valType, index) => {
      const x = new Map()
      oridata.forEach(item => {
        if (x.has(item[`value${index + 1}`])) {
          x.set(item[`value${index + 1}`], x.get(item[`value${index + 1}`]) + 1)
        } else {
          x.set(item[`value${index + 1}`], 1)
        }
      })
      const data: Plotly.Data[] = [
        {
          x:
            valType > 2
              ? oridata
                  .map(item => item[`value${index + 1}`])
                  .filter(num => typeof num === 'number')
              : [...x.keys()],
          y: valType > 2 ? undefined : [...x.values()],
          type: valType > 2 ? 'histogram' : 'bar',
          marker: {
            color: '#FFA54F',
            line: {
              color: 'rgba(255,255,255,0.5)',
              width: 1
            }
          }
        }
      ]
      const layout: Partial<Plotly.Layout> = {
        title: `${trait?.id}${valTypes.length > 1 ? '-' + (index + 1) : ''} (${
          trait?.name
        })`,
        xaxis: {
          automargin: true
        },
        yaxis: {
          showline: true,
          automargin: true,
          ticklen: 5,
          tick0: 2
        }
      }
      const config: Partial<Plotly.Config> = {
        toImageButtonOptions: {
          filename: `phenome_${category}_${trait.category}(${trait.id}).svg`,
          format: 'svg'
        },
        displaylogo: false
      }

      const element = document.createElement('div')

      const plotlyDiv = await Plotly.newPlot(element, data, layout, config)
      // plotlyDiv.scrollIntoView({
      //   behavior: 'smooth'
      // })
      divRef.current?.appendChild(element)
      plotlyDiv.scrollIntoView({
        behavior: 'smooth'
      })
    })
  }

  const getPlotData = async (trait: traitType.TraitItem) => {
    const res = await traitService.getData(category, trait.id)

    if (res.stat === 'ok') {
      setPlotData(res.data.rows)
      setTableData(res.data.rows)
      setValTypes(res.data.valTypes)
      res.data.rows.length
        ? drawPlot(res.data.rows, res.data.valTypes, trait)
        : message.error(`No ${trait.name} Phenomics Data`)
    } else {
      setPlotData(undefined)
      setTableData(undefined)
      message.error(`No ${trait.name} Phenomics Data`)
    }
    setCurrent(1)
  }

  const tabsColumns: ColumnsType<traitType.TraitItem> = useMemo(
    () => [
      {
        title: 'No.',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: 'Trait ID',
        dataIndex: 'id',
        key: 'id',
        width: 120
      },
      {
        title: 'Trait name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: 200
        // sorter: (a, b) => a < b ? -1 : 1
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        width: 200
      },
      {
        title: 'Action',
        key: 'action',
        width: 200,
        render: (_, record) => (
          <Space size="middle">
            <a
              className={styles.table_btns}
              key={JSON.stringify(record)}
              onClick={() => {
                setTrait(record)
                getPlotData(record)
              }}
            >
              show
            </a>
            <a
              className={styles.table_btns}
              key={record.id}
              onClick={() => {
                history.push({
                  pathname: '/search/gwas',
                  state: {
                    trait: record.id,
                    key: category,
                    category: record.category
                  }
                })
                // history.push(`/search/gwas?key=${category}&cat=${record.category}&trait=${record.id}`)
              }}
            >
              jump to GWAS
            </a>
          </Space>
        )
      }
    ],
    [category, tabsTableData]
  )

  const getTraitData = async () => {
    const [agrRes, metRes, stressRes] = await Promise.all([
      traitService.getList('agronomic'),
      traitService.getList('metabolic'),
      traitService.getList('stress')
    ])

    if (stressRes.stat === 'ok') {
      setStressData(stressRes.data.rows)
      setTabsTableData(agrRes.data.rows)
    }

    if (metRes.stat === 'ok') {
      setMetabolicData(metRes.data.rows)
      setTabsTableData(agrRes.data.rows)
    }

    if (agrRes.stat === 'ok') {
      setAgronomicData(agrRes.data.rows)
      setTabsTableData(agrRes.data.rows)
    }
  }

  const filterTabsTableData = (
    data: traitType.TraitItem[],
    keyword: string = ''
  ) => {
    setTabsTableData(
      keyword
        ? data?.filter(
            item =>
              new RegExp(keyword, 'ig').test(item.id) ||
              new RegExp(keyword, 'ig').test(item.category) ||
              new RegExp(keyword, 'ig').test(item.name)
          )
        : data
    )
  }

  useEffect(() => {
    tabsTableData && setLoading(false)
  }, [tabsTableData])

  useEffect(() => {
    getTraitData()
  }, [])

  return (
    <>
      <div className={styles.container}>
        <h1>Phenotype Information</h1>
        <p></p>

        <div className={styles.content}>
          <Tabs
            className={styles.tabs}
            onChange={key => {
              setLoading(true)
              setCategory(key as traitType.Key)
              setTabsTableData(
                key === 'agronomic'
                  ? agronomicData
                  : key === 'metabolic'
                  ? metabolicData
                  : stressData
              )
            }}
            type="card"
          >
            <TabPane tab="Agronomic Traits" key="agronomic"></TabPane>
            <TabPane tab="Metabolic Traits" key="metabolic"></TabPane>
            <TabPane tab="Stress Traits" key="stress"></TabPane>
          </Tabs>

          <div>
            <Search
              className={styles.searchBtn}
              // width={200}
              placeholder="Trait ID、Trait name or Category"
              enterButton="Search"
              onSearch={keyword =>
                filterTabsTableData(
                  category === 'agronomic' ? agronomicData : metabolicData,
                  keyword.trim()
                )
              }
            />
            <Table
              sticky
              className={styles.table}
              loading={loading}
              columns={tabsColumns}
              dataSource={tabsTableData}
              scroll={{ y: 465, scrollToFirstRowOnChange: true }}
              size="small"
              rowKey="id"
              bordered={true}
              pagination={false}
              onChange={pagination => {
                setCurrent(pagination.current as number)
                setLimit(pagination.pageSize as number)
              }}
            />
          </div>

          <div
            style={{ display: plotData?.length ? 'grid' : 'none' }}
            ref={divRef}
            className={styles.plot}
          ></div>

          <div
            className={styles.result_table}
            style={{ display: plotData?.length ? 'block' : 'none' }}
          >
            <div className={styles.tableBtn}>
              <Search
                placeholder="Accession or Group"
                enterButton="search"
                size="middle"
                // defaultValue={'5'}
                onSearch={keyword =>
                  setTableData(
                    keyword
                      ? plotData?.filter(
                          item =>
                            new RegExp(keyword, 'ig').test(item.accession) ||
                            new RegExp(keyword, 'ig').test(item.group)
                        )
                      : plotData
                  )
                }
              />
              <Button
                size="middle"
                onClick={() => {
                  const title = `Accession\tGroup\tTaxon\t${valTypes
                    .map(
                      (_, i) =>
                        `${trait?.id}${
                          valTypes.length > 1 ? '-' + (i + 1) : ''
                        }`
                    )
                    .join('\t')}\n`

                  const table = tableData
                    ?.map(item =>
                      [
                        item.accession,
                        item.group,
                        item.taxon,
                        ...valTypes.map((_, i) => item[`value${i + 1}`])
                      ].join('\t')
                    )
                    .join('\n')

                  downloadFile(
                    URL.createObjectURL(
                      new Blob([title + table], {
                        type: 'text/plain' + ';charset=' + 'utf-8'
                      })
                    ),
                    `${category}_${trait?.category}_${trait?.id}.txt`
                  )
                }}
              >
                Download
              </Button>
            </div>
            <Table
              sticky
              className={styles.table}
              columns={phenomeColumns}
              dataSource={tableData}
              size="small"
              rowKey="accession"
              bordered={true}
              pagination={{
                current,
                pageSize: limit,
                position: ['topLeft', 'bottomLeft']
              }}
              onChange={pagination => {
                setCurrent(pagination.current as number)
                setLimit(pagination.pageSize as number)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Phenotype
