import request, { BaseRes } from '@/libs/request'
import { gwasType } from '../types'

const PREFIX = '/api/gwas'

export const gwas = async (body: gwasType.IGwasBody) => {
  const { data } = await request.post<BaseRes<gwasType.IGwasResponse>>(PREFIX + '/list', body)
  return data
}

export const gwasFigure = async (trait: string) => {
  const { data } = await request.post<BaseRes<gwasType.IFigureResponse>>(PREFIX + '/figure', { trait })
  return data
}
