import { useState } from 'react'
import { Input } from 'antd'
import { TVDInputContainer, TVDMulSelect, TVDTextarea } from '@/components'
import styles from './style.module.less'

const popOptions = ['SLL', 'SLC', 'SP', 'WILD']
const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const VVariation = () => {
  const { TextArea } = Input
  const [variationIds, setVariationIds] = useState('')

  return (
    <>
      <h2>Search for Variation by Variation ID:</h2>
      <div>
        <TVDTextarea
          {...CONFIG}
          title="Variation IDs"
          placeholder="Please input Variation IDs"
          example="Try Example"
          value={variationIds}
          allowClear={true}
          height={200}
          onChange={v => setVariationIds(v)}
          setExample={() =>
            setVariationIds(
              'SL4.0ch01_3971781\nSL4.0ch01_3971917\nSL4.0ch01_3971935\nSL4.0ch01_3971958\nSL4.0ch01_3971978\nSL4.0ch01_3971993\nSL4.0ch01_3972212\nSL4.0ch01_3972229\nSL4.0ch01_3972457\nSL4.0ch01_3972477\nSL4.0ch01_3972580\nSL4.0ch01_3972599'
            )
          }
        />
      </div>
    </>
  )
}

export default VVariation
