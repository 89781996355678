import request, { BaseRes } from '@/libs/request'
import { panType } from '../types'

const PREFIX = '/api/pan'

export const getGenomeData = async () => {
  const { data } = await request.get<BaseRes<panType.PanGenomeRes>>(PREFIX + '/genome/list')
  return data
}

export const getTranData = async () => {
  const { data } = await request.get<BaseRes<panType.PanTranRes>>(PREFIX + '/tran/list')
  return data
}
