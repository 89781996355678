import { useEffect, useRef, useState } from 'react'
import { Button, Input, InputNumber, Select, Table, Tabs } from 'antd'
import { panService, traitService } from '@/services'
import { commonType, panType, traitType } from '@/types'
import * as Plotly from 'plotly.js-dist-min'
import styles from './style.module.less'
import {
  TVDChromosomeSelect,
  TVDInputContainer,
  TVDTextarea
} from '@/components'

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    key: 'index',
    width: 45
  },
  {
    title: 'Accession',
    dataIndex: 'accession',
    key: 'accession',
    width: 120
  },
  {
    title: 'Total Gene',
    dataIndex: 'totalGene',
    key: 'totalGene'
  },
  {
    title: 'Total Transcript',
    dataIndex: 'totalTranscript',
    key: 'totalTranscript'
  },
  {
    title: 'Coding Gene',
    dataIndex: 'codingGene',
    key: 'codingGene'
  },
  {
    title: 'Coding Transcript',
    dataIndex: 'codingTranscript',
    key: 'codingTranscript'
  },
  {
    title: 'ITAG4.0 Gene',
    dataIndex: 'itagGene',
    key: 'itagGene'
  },
  {
    title: 'ITAG4.0 Transcript',
    dataIndex: 'itagTranscript',
    key: 'itagTranscript'
  }
]

const chromosomeData: commonType.Chromosome[] = [
  'ch00',
  'ch01',
  'ch02',
  'ch03',
  'ch04',
  'ch05',
  'ch06',
  'ch07',
  'ch08',
  'ch09',
  'ch10',
  'ch11',
  'ch12'
]

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const Transcriptome = () => {
  const { Search } = Input
  const { TabPane } = Tabs
  const [sequence, setSequence] = useState<string>()
  const [ids, setIds] = useState<string>()

  const [data, setData] = useState<panType.PanTranItem[]>([])
  const [tableData, setTableData] = useState<panType.PanTranItem[]>([])
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(10)
  const [loading, setLoading] = useState(true)
  const [start, setStart] = useState<number>()
  const [end, setEnd] = useState<number>()

  const getData = async () => {
    const res = await panService.getTranData()
    if (res.stat === 'ok') {
      const data = res.data.rows.map((item, i) => ({ ...item, index: i + 1 }))
      setData(data)
      setTableData(data)
      setLoading(false)
    }
  }

  const filterData = (keyword: string) => {
    const newData = keyword
      ? data.filter(item => new RegExp(keyword, 'ig').test(item.accession))
      : data
    setTableData(newData.map((item, i) => ({ ...item, index: i + 1 })))
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className={styles.container}>
      <h1>Transcriptome</h1>
      <p></p>

      <div className={styles.content}>
        <Tabs
          className={styles.tabs}
          // onChange={key => setCategory(key as traitType.Key)}
          type="card"
        >
          <TabPane tab="By Region" key="region">
            <TVDChromosomeSelect
              {...CONFIG}
              options={chromosomeData}
              className={styles.chromosome}
            />

            <TVDInputContainer
              {...CONFIG}
              title="Region"
              component={
                <Input.Group compact>
                  <span className={styles.input_group_span}>Start</span>
                  <InputNumber
                    className={styles.groupInputNumber}
                    placeholder="Start Position"
                    min={0}
                    max={end}
                    value={start}
                    onChange={n => setStart(n)}
                  />
                  <span className={styles.input_group_span}>End</span>
                  <InputNumber
                    className={styles.groupInputNumber}
                    placeholder="End Position"
                    min={start ?? 0}
                    value={end}
                    onChange={n => setEnd(n)}
                  />
                </Input.Group>
              }
              afterComponent={
                <span>
                  <span>
                    eg:{' '}
                    <span
                      className="after_eg"
                      onClick={() => {
                        setStart(2000)
                        setEnd(20000)
                      }}
                    >
                      2000-20000
                    </span>
                    , input a range (&lt; 100 kb)
                  </span>
                </span>
              }
            />
          </TabPane>

          <TabPane tab="By Gene" key="gene">
            <TVDTextarea
              title="Input IDs"
              placeholder="Please input IDs"
              example="Try Example"
              allowClear={true}
              value={ids}
              titlewidth={160}
              textareawidth={520}
              height={200}
              onChange={v => setIds(v)}
              setExample={() => {
                setIds(
                  'Solyc12g100270\nSolyc12g100280\nSolyc12g100290\nSolyc12g100300\nSolyc12g100310\nSolyc12g100320\nSolyc12g100330\nSolyc12g100340\nSolyc12g100350\nSolyc12g100360'
                )
              }}
            />
          </TabPane>

          <TabPane tab="By Sequence" key="sequence">
            <TVDTextarea
              title="Input Sequence"
              placeholder="Please input Sequence"
              example="Try Example"
              allowClear={true}
              value={sequence}
              titlewidth={160}
              textareawidth={520}
              height={200}
              onChange={v => setSequence(v)}
              setExample={() => {
                setSequence(
                  'ATGGATTGTGTAAAACTTGTGTTTTTCATGCTATATACCTTTCTCTGTCAACTTGCTTTCTCCTCATCCTCACCTCATTTATGCCCGATAGATCAAGCTCTTTCTCTTCTACAATTCAAGAACATGTTCAACATTAATCCTAATGCTTCTGATTATTGTTACGACATAAGTACAGGCGTAGAGATTCAGTCATATCCAAGAACTCTTTTCTGGAACAAGAGTACAGATTGTTGCTCATGGGATGGAGTTCACTGTGACAAGATGATAGGACTAGTGATTGAGCTTGATCTCCGTTGCAGCCAACTTCAAGGCAAGTTTCATTCCAATGGTAGCCTCTTTCAACTCTTCAATCTCAAAAGGCTTGATTTGTCTTTTAATAATTTCACCGGATCACTCATTTCACCTAAATTTGGTGAATTCTCTAGTTTGATGCATCTTGATTTGTTGGATTCAGGTTTTACAGGTATAATCCCTTCCGAAATTTGTCACCTTTCTAAACTACGTTCTGCGTATCTCGACTGA'
                )
              }}
            />
          </TabPane>
        </Tabs>

        <Button className={styles.submit} type="primary">
          Submit
        </Button>

        <div className={styles.tableDiv}>
          {!loading && (
            <Search
              placeholder="Accession"
              allowClear
              className={styles.searchBtn}
              enterButton="Search"
              size="middle"
              onSearch={filterData}
            />
          )}
          <Table
            className={styles.table}
            columns={columns}
            dataSource={tableData}
            loading={loading}
            size="small"
            rowKey="accession"
            bordered={true}
            pagination={{
              position: ['topRight', 'bottomRight']
            }}
            sticky
          />
        </div>
      </div>
    </div>
  )
}

export default Transcriptome
