import { ChangeEvent, useEffect, useState } from 'react'
import {
  Radio,
  message,
  RadioChangeEvent,
  Tabs,
  Button,
  Input,
  Table,
  Select
} from 'antd'
import { TVDInputContainer, TVDMulSelect, TVDTextarea } from '@/components'
import VRegion from './VRegion'
import VVariation from './VVariationId'
import VGene from './VGene'
import styles from './style.module.less'
import { gwasType, panType, variationType } from '@/types'
import { panService, variationService } from '@/services'

type Type = 'Population' | 'Individual'

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    key: 'index',
    width: 45
  },
  {
    title: 'CHROM',
    dataIndex: 'chrom',
    key: 'chrom',
    width: 100
  },
  {
    title: 'Pos',
    dataIndex: 'pos',
    key: 'pos',
    width: 90
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Ref',
    dataIndex: 'ref',
    key: 'ref',
    width: 50
  },
  {
    title: 'Allele',
    dataIndex: 'allele',
    key: 'allele',
    width: 60
  },
  {
    title: 'AN',
    dataIndex: 'an',
    key: 'an',
    width: 50
  },
  {
    title: 'AF',
    dataIndex: 'af',
    key: 'af',
    width: 80
  },
  {
    title: 'AC',
    dataIndex: 'ac',
    key: 'ac',
    width: 70
  },
  {
    title: 'Var_type',
    dataIndex: 'varType',
    key: 'varType',
    width: 90
  },
  {
    title: 'Gene',
    dataIndex: 'gene',
    key: 'gene'
  },
  {
    title: 'WILD',
    dataIndex: 'wild',
    key: 'wild',
    width: 70
  },
  {
    title: 'SP',
    dataIndex: 'sp',
    key: 'sp',
    width: 80
  },
  {
    title: 'SLC',
    dataIndex: 'slc',
    key: 'slc',
    width: 100
  },
  {
    title: 'SLL',
    dataIndex: 'sll',
    key: 'sll',
    width: 90
  }
]

const popOptions = ['SLL', 'SLC', 'SP', 'WILD']

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const Variation = () => {
  const { Search } = Input
  const { Option } = Select
  const { TabPane } = Tabs
  const [data, setData] = useState<variationType.VariationItem[]>([])
  const [tableData, setTableData] = useState<variationType.VariationItem[]>([])
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(10)
  const [loading, setLoading] = useState(true)
  const [refGenome, setRefGenome] = useState<gwasType.RefGenome>('SL4.0')
  const [population, setPopulation] = useState<string[]>([
    'SLL',
    'SLC',
    'SP',
    'WILD'
  ])
  const [variationType, setVariationType] =
    useState<gwasType.VariationType>('Total')
  const [chromosome, setChromosome] = useState<gwasType.Chromosome>('all')
  const [type, setType] = useState<Type>('Population')
  const [accession, setAccession] = useState<string>()

  const getData = async () => {
    const res = await variationService.getVariationData()
    if (res.stat === 'ok') {
      const data = res.data.rows.map((item, i) => ({ ...item, index: i + 1 }))
      setData(data)
      setTableData(data)
      setLoading(false)
    }
  }

  const filterData = (keyword: string) => {
    const newData = keyword
      ? data.filter(
          item =>
            new RegExp(keyword, 'ig').test(item.chrom) ||
            new RegExp(keyword, 'ig').test(item.pos) ||
            new RegExp(keyword, 'ig').test(item.id) ||
            new RegExp(keyword, 'ig').test(item.varType) ||
            new RegExp(keyword, 'ig').test(item.gene)
        )
      : data
    setTableData(newData.map((item, i) => ({ ...item, index: i + 1 })))
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className={styles.container}>
      <h1>Variation</h1>
      <p></p>
      <div className={styles.content}>
        <Tabs
          className={styles.tabs}
          onChange={key => console.log(key)}
          type="card"
        >
          <TabPane tab="By Region" key="region">
            <VRegion />
          </TabPane>
          <TabPane tab="By Variation ID" key="variation">
            <VVariation />
          </TabPane>
          <TabPane tab="By Gene" key="gene">
            <VGene />
          </TabPane>
        </Tabs>

        <div style={{ marginLeft: '15px' }}>
          <TVDInputContainer
            {...CONFIG}
            title="Variation Type"
            component={
              <Radio.Group
                value={variationType}
                onChange={e => setVariationType(e.target.value)}
              >
                <Radio value={'Total'}>Total</Radio>
                <Radio value={'SNP'}>SNP</Radio>
                <Radio value={'InDel'}>InDel</Radio>
                <Radio value={'SV'}>SV</Radio>
                <Radio value={'PAV'}>PAV</Radio>
              </Radio.Group>
            }
          />

          <TVDInputContainer
            {...CONFIG}
            title={
              <Select
                className={styles.titleComponent}
                value={type}
                onChange={(v: Type) => setType(v)}
              >
                <Option value="Population">Population</Option>
                <Option value="Individual">Individual</Option>
              </Select>
            }
            afterComponent={
              type === 'Individual' ? (
                <span>
                  <span
                    className="after_eg"
                    onClick={() => {
                      setAccession(
                        'TS-128\nTS-125\nTS-12\nTS-98\nTS-99\nTS-87\nTS-706\nTS-707\nTS-407\nTS-18\nTS-164\nTS-15'
                      )
                    }}
                  >
                    Try example
                  </span>
                </span>
              ) : undefined
            }
            component={
              type === 'Population' ? (
                <Select
                  // placeholder={placeholder}
                  optionLabelProp="label"
                  mode="multiple"
                  value={population}
                  onChange={vals => setPopulation(vals)}
                >
                  {popOptions.map(item => (
                    <Option key={item} value={item} label={item}>
                      <div className="demo-option-label-item">{item}</div>
                    </Option>
                  ))}
                </Select>
              ) : (
                <div>
                  <TVDTextarea
                    {...CONFIG}
                    placeholder="Please input Accession"
                    example="Try Example"
                    value={accession}
                    allowClear={true}
                    height={200}
                    onChange={v => setAccession(v)}
                  />
                </div>
              )
            }
          />
        </div>

        <div className={styles.btn_contaienr}>
          <Button
            type="primary"
            onClick={() => {
              message.info('你点击了提交按钮')
            }}
          >
            Submit
          </Button>
        </div>

        <div className={styles.tableDiv}>
          {!loading && (
            <Search
              placeholder="CHROM、Pos、ID、Var_type or Gene"
              allowClear
              className={styles.searchBtn}
              enterButton="Search"
              size="middle"
              onSearch={filterData}
            />
          )}
          <Table
            className={styles.table}
            columns={columns}
            dataSource={tableData}
            loading={loading}
            size="small"
            rowKey="id"
            bordered={true}
            pagination={{
              position: ['topRight', 'bottomRight']
            }}
            sticky
          />
        </div>
      </div>
    </div>
  )
}

export default Variation
