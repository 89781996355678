import { useState } from 'react'
import request from 'axios'
import styles from './style.module.less'

const Download = () => {
  const [limit, setLimit] = useState(5000)

  const test = async () => {
    const time1 = new Date().getTime()
    const res = await request('/api/gwas/list', {
      method: 'post',
      data: {
        limit
      }
    })
    const time2 = new Date().getTime()
    console.log(res)
    console.log((time2 - time1) / 1000, 's')
  }

  return (
    <div className={styles.container}>
      <h1>Download</h1>
      <div></div>
    </div>
  )
}

export default Download
