import request, { BaseRes } from '@/libs/request'
import { popType } from '../types'

const PREFIX = '/api/population'

export const getData = async (kmer: popType.Kmer) => {
  const { data } = await request.post<BaseRes<popType.PopRes>>(PREFIX + '/list', {
    kmer
  })
  return data
}
