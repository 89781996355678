import React from 'react'
import ReactDOM from 'react-dom'
import App from '@/pages/App'
import 'antd/dist/antd.less'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
)
