import { useState } from 'react'
import { TVDInputContainer } from '@/components'
import styles from './style.module.less'
import { Button, Input, message } from 'antd'

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const Gene = () => {
  const [id, setId] = useState('')

  return (
    <div className={styles.container}>
      <h1>Gene</h1>
      <p></p>
      <div className={styles.content}>
        <div className={styles.flex}>
          <TVDInputContainer
            {...CONFIG}
            title="Gene ID"
            component={
              <Input value={id} onChange={e => setId(e.target.value)} />
            }
          />
          <p>
            Please input a Gene ID (eg:
            <span
              style={{ color: '#0C64B6', cursor: 'pointer' }}
              onClick={() => setId('Solyc01g102660')}
            >
              Solyc01g102660
            </span>
            ).
          </p>
        </div>

        <div className={styles.btn_contaienr}>
          <Button
            type="primary"
            onClick={() => {
              message.info('你点击了提交按钮')
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Gene
