import { useState } from 'react'
import { Button, Input, InputNumber, Select, Tabs } from 'antd'
import {
  TVDChromosomeSelect,
  TVDInputContainer,
  TVDTextarea
} from '@/components'
import styles from './style.module.less'
import { Type } from '@/types/common.type'
import { gwasType } from '@/types'

const popOptions = ['SLL', 'SLC', 'SP', 'WILD']

const chromosomeOptions: gwasType.Chromosome[] = [
  'ch00',
  'ch01',
  'ch02',
  'ch03',
  'ch04',
  'ch05',
  'ch06',
  'ch07',
  'ch08',
  'ch09',
  'ch10',
  'ch11',
  'ch12'
]

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const Introgression = () => {
  const { TabPane } = Tabs
  const { Option } = Select
  const [id, setId] = useState('')
  const [type, setType] = useState<Type>('Population')
  const [chromosome, setChromosome] = useState<gwasType.Chromosome>('ch01')
  const [start, setStart] = useState<number>()
  const [end, setEnd] = useState<number>()
  const [population, setPopulation] = useState<string[]>(popOptions)
  const [geneIds, setGeneIds] = useState('')

  const [accession, setAccession] = useState<string>('')

  return (
    <div className={styles.container}>
      <h1>Introgression</h1>
      <p></p>
      <div className={styles.content}>
        <Tabs
          className={styles.tabs}
          onChange={key => console.log(key)}
          type="card"
        >
          <TabPane tab="By Region" key="region">
            <TVDChromosomeSelect
              {...CONFIG}
              options={chromosomeOptions}
              value={chromosome}
              onChange={v => setChromosome(v)}
            />

            <TVDInputContainer
              {...CONFIG}
              title="Region"
              component={
                <Input.Group compact>
                  <span className={styles.input_group_span}>Start</span>
                  <InputNumber
                    className={styles.groupInputNumber}
                    placeholder="Start Position"
                    min={0}
                    max={end}
                    value={start}
                    onChange={start => setStart(start)}
                  />
                  <span className={styles.input_group_span}>End</span>
                  <InputNumber
                    className={styles.groupInputNumber}
                    placeholder="End Position"
                    min={start ?? 0}
                    value={end}
                    onChange={end => setEnd(end)}
                  />
                </Input.Group>
              }
              afterComponent={
                <span>
                  <span>
                    eg:{' '}
                    <span
                      className="after_eg"
                      onClick={() => {
                        setStart(2000)
                        setEnd(20000)
                      }}
                    >
                      2000-20000
                    </span>
                    , input a range (&lt; 100 kb)
                  </span>
                </span>
              }
            />
          </TabPane>
          <TabPane tab="By Gene" key="gene">
            <TVDTextarea
              {...CONFIG}
              title="Gene IDs"
              placeholder="Please input Gene IDs"
              example="Try Example"
              value={geneIds}
              allowClear={true}
              titlewidth={160}
              height={200}
              onChange={v => setGeneIds(v)}
              setExample={() =>
                setGeneIds(
                  'Solyc12g100270\nSolyc12g100280\nSolyc12g100290\nSolyc12g100300\nSolyc12g100310\nSolyc12g100320\nSolyc12g100330\nSolyc12g100340\nSolyc12g100350\nSolyc12g100360'
                )
              }
            />
          </TabPane>
        </Tabs>

        <TVDInputContainer
          {...CONFIG}
          title={
            <Select
              className={styles.titleComponent}
              value={type}
              onChange={(v: Type) => setType(v)}
            >
              <Option value="Population">Population</Option>
              <Option value="Individual">Individual</Option>
            </Select>
          }
          afterComponent={
            type === 'Individual' ? (
              <span>
                <span
                  className="after_eg"
                  onClick={() => {
                    setAccession(
                      'TS-12\nTS-125\nTS-12\nTS-98\nTS-99\nTS-87\nTS-706\nTS-707\nTS-407\nTS-18\nTS-164\nTS-15'
                    )
                  }}
                >
                  Try example
                </span>
              </span>
            ) : undefined
          }
          component={
            type === 'Population' ? (
              <Select
                // placeholder={placeholder}
                optionLabelProp="label"
                mode="multiple"
                value={population}
                onChange={vals => setPopulation(vals)}
              >
                {popOptions.map(item => (
                  <Option key={item} value={item} label={item}>
                    <div className="demo-option-label-item">{item}</div>
                  </Option>
                ))}
              </Select>
            ) : (
              <div>
                <TVDTextarea
                  {...CONFIG}
                  placeholder="Please input Accession"
                  // example="Try Example"
                  value={accession}
                  allowClear={true}
                  height={200}
                  onChange={v => {}}
                />
              </div>
            )
          }
        />

        <Button className={styles.submit} type="primary">
          Submit
        </Button>
      </div>
    </div>
  )
}

export default Introgression
