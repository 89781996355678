import { useState } from 'react'
import { Select, message, InputNumber, Input, Button } from 'antd'
import {
  TVDChromosomeSelect,
  TVDInputContainer,
  TVDMulSelect,
  TVDTextarea
} from '@/components'
import styles from './style.module.less'
import { gwasType } from '@/types'

const popOptions = ['SLL', 'SLC', 'SP', 'WILD']
const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const HaplotypeNetworkAnalysis = () => {
  const [variationId, setVariationId] = useState('')
  const [population, setPopulation] = useState<string[]>([...popOptions])
  const [chromosome, setChromosome] = useState<gwasType.Chromosome>('all')

  return (
    <div className={styles.container}>
      <h1>Haplotype Network Analysis</h1>
      <p></p>
      <div className={styles.content}>
        <TVDTextarea
          {...CONFIG}
          title="Variation ID"
          placeholder="Please input Variation ID"
          // example="Try Example"
          value={variationId}
          height={200}
          onChange={v => setVariationId(v)}
          setExample={
            () => {}
            // setAccession(
            //   'TS-128\nTS-125\nTS-12TS-98\nTS-99\nTS-87\nTS-706\nTS-707\nTS-407\nTS-18\nTS-164\nTS-15'
            // )
          }
        />

        <TVDMulSelect
          {...CONFIG}
          options={popOptions}
          value={population}
          onChange={v => setPopulation(v)}
        />

        <div className={styles.btn_contaienr}>
          <Button
            type="primary"
            onClick={() => {
              message.info('你点击了提交按钮')
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default HaplotypeNetworkAnalysis
