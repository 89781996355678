import { loadingGif } from '@/assets'
import { useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './style.module.less'

interface Props {
    imgUrl: string
    text: string
    route: string
    className?: string
    onClick?: () => void
    observer: IntersectionObserver
  }
  
  const Card = (props: Props) => {
    const history = useHistory()
    const imgRef = useRef<HTMLImageElement>(null)
  
    useEffect(() => {
      if (props.observer && imgRef.current) {
        props.observer.observe(imgRef.current)
      }
    }, [props.observer])
  
    return (
      <div
        className={styles['module-card'] + ' ' + props.className}
        onClick={() =>
          props.onClick ? props.onClick() : history.push(props.route)
        }
      >
        <img src={loadingGif} alt="" ref={imgRef} data-src={props.imgUrl} />
        <p>{props.text}</p>
      </div>
    )
  }

  export default Card
