import { useState, useEffect, useRef } from 'react'
import {
  TVDInputContainer,
  TVDMulSelect,
  TVDChromosomeSelect
} from '@/components'
import {
  Button,
  Checkbox,
  Empty,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Table
} from 'antd'
import { traitType, gwasType } from '@/types'
import { traitService, gwasService } from '@/services'
import Plotly from 'plotly.js-dist-min'
import * as echarts from 'echarts'
import { DefaultLabelFormatterCallbackParams } from 'echarts/types/dist/echarts'
import saveSvg from '@/assets/icons/save.svg'
import { downloadFile } from '@/utils'
import { ColumnsType } from 'antd/lib/table'

import styles from './style.module.less'
import { useHistory } from 'react-router-dom'

type EChartsOption = echarts.EChartsOption

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const columns: ColumnsType<gwasType.IGwasItem> = [
  {
    title: 'Chromosome',
    dataIndex: 'chr',
    key: 'chr',
    width: 120
  },
  {
    title: 'SL',
    dataIndex: 'refGenome',
    key: 'refGenome',
    width: 80
  },
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    sorter: (itemA, itemB) => itemB.position - itemA.position,
    width: 100
  },
  {
    title: '-log10 (P-value)',
    dataIndex: 'pValue',
    key: 'pValue',
    sorter: (itemA, itemB) => itemB.pValue - itemA.pValue,
    width: 150
  },
  {
    title: 'Gene',
    dataIndex: 'gene',
    key: 'gene'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'All',
    dataIndex: 'All',
    key: 'All',
    width: 70
  },
  {
    title: 'SLL',
    dataIndex: 'SLL',
    key: 'SLL',
    width: 50
  },
  {
    title: 'SLC',
    dataIndex: 'SLC',
    key: 'SLC',
    width: 70
  },
  // {
  //   title: 'GX',
  //   dataIndex: 'GX',
  //   key: 'GX',
  //   width: 70
  // },
  {
    title: 'SP',
    dataIndex: 'SPIM',
    key: 'SPIM',
    width: 70
  },
  {
    title: 'WILD',
    dataIndex: 'WILD',
    key: 'WILD',
    width: 70
  }
]

const GWAS = () => {
  const { Option } = Select
  const { Search } = Input
  const history = useHistory()
  const plotlyRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<HTMLDivElement>(null)
  const myChart = useRef<echarts.ECharts>()
  const [isLoading, setIsloading] = useState(false)

  const [categoriesData, setCategoriesData] = useState<string[]>([])
  const [traitsData, setTraitsData] = useState<traitType.TraitItem[]>([])
  const [gwasData, setGwasData] = useState<gwasType.IGwasItem[]>()
  const [tableData, setTableData] = useState<gwasType.IGwasItem[]>([])
  const [images, setImages] = useState<{ manImage: string; qqImage: string }>()
  const [curChr, setCurChr] = useState('')

  const [checked, setChecked] = useState(false)
  const [downloadType, setDownloadType] = useState<
    'svg' | 'png' | 'jpeg' | 'webp'
  >('svg')
  const [refGenome, setRefGenome] = useState<gwasType.RefGenome>('SL4.0')
  const [variationType, setVariationType] =
    useState<gwasType.VariationType>('SNP')
  const [population, setPopulation] = useState<string[]>([
    'SLL',
    'SLC',
    'SP',
    'WILD'
  ])
  const [model, setModel] = useState<'GLM' | 'MLM'>('GLM')
  const [traitKey, setTraitKey] = useState<traitType.Key>()
  const [category, setCategory] = useState('')
  const [traitId, setTraitId] = useState('')
  const [chromosome, setChromosome] = useState<gwasType.Chromosome>('all')
  const [min, setMin] = useState<number>()
  const [max, setMax] = useState<number>()

  const drawImage = (chartData: gwasType.IGwasItem[], name: string) => {
    const option: EChartsOption = {
      title: {
        text: 'Section Scatter Plot'
      },
      tooltip: {
        trigger: 'item',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        formatter: params => {
          const { data } = params as DefaultLabelFormatterCallbackParams
          return `
          <span>-log10 (P-value): ${(data as any)[1]}</span>
          <br/>
          <span>position: ${(data as any)[0]}</span>
          <br/>
          <span>gene: ${(data as any)[4]}</span>
          <br/>
          <span>type: ${(data as any)[5]}</span>
          `
        }
      },
      axisPointer: {
        label: {
          backgroundColor: '#777'
        }
      },
      toolbox: {
        right: 30,
        feature: {
          dataZoom: {},
          myDownload: {
            show: true,
            title: 'Save as SVG',
            icon: 'image://' + saveSvg,
            onclick: function () {
              const content = myChart.current?.getConnectedDataURL({
                type: 'svg'
              })!
              downloadFile(content, 'tvd_gwas.svg')
            }
          }
        }
      },
      xAxis: {
        name: 'SL4.0' + name + ' (Mb)',
        nameLocation: 'middle',
        splitLine: { show: false },
        axisLabel: {
          formatter: (value: number, index: number) =>
            `${(value / 1000000).toFixed(3)}`
        },
        min: 'dataMin',
        nameTextStyle: {
          fontFamily: 'sans-serif',
          fontSize: 20,
          verticalAlign: 'middle'
        },
        nameGap: 40
      },
      yAxis: {
        nameLocation: 'middle',
        splitLine: { show: false },
        name: '-log10 ( P )',
        nameTextStyle: {
          fontFamily: 'sans-serif',
          fontSize: 20,
          verticalAlign: 'middle'
        },
        nameRotate: 90,
        nameGap: 60
      },
      dataZoom: {
        type: 'inside'
      },
      animation: true,
      series: {
        name,
        type: 'scatter',
        data: chartData.map(item => [
          item.position,
          item.pValue,
          item.chr,
          item.refGenome,
          item.gene,
          item.type
        ]),
        dimensions: ['x', 'y'],
        symbolSize: 5,
        itemStyle: {
          opacity: 0.7
        },
        large: true
      }
    }

    myChart.current!.setOption(option)
  }

  const filterTableData = async (
    data: gwasType.IGwasItem[] = gwasData ?? [],
    pV: number = 5
  ) => {
    const cell = 50000
    const [...newData] = await Promise.all(
      new Array(Math.ceil(data.length / cell))
        .join(',')
        .split(',')
        .map(async (_, index) =>
          data
            .slice(index * cell, (index + 1) * cell)
            .filter(item => item.pValue >= pV)
        )
    )
    setTableData(newData.flat().sort((a, b) => b.pValue - a.pValue))
  }

  const handleSubmit = async (trait: string) => {
    if (checked) {
      if (typeof min !== 'number' && typeof max !== 'number') {
        message.error('minnum & maxnum')
        return
      } else if (typeof min !== 'number') {
        message.error('minnum')
        return
      } else if (typeof max !== 'number') {
        message.error('minnum & maxnum')
        return
      }
    }
    setIsloading(true)
    setGwasData(undefined)
    setTableData([])
    myChart.current!.clear()
    try {
      const [gwasRes, figureRes] = await Promise.all([
        gwasService.gwas({
          chr: chromosome,
          refGenome,
          variationType,
          population,
          model,
          trait,
          min: checked ? min : undefined,
          max: checked ? max : undefined
        }),
        gwasService.gwasFigure(trait)
      ])

      if (figureRes.stat === 'ok' && gwasRes.stat === 'ok') {
        setGwasData(gwasRes.data.rows)
        setCurChr(gwasRes.data.chr)
        filterTableData(gwasRes.data.rows)
        setImages(figureRes.data)
        gwasRes.data.chr !== 'all' &&
          drawImage(gwasRes.data.rows, gwasRes.data.chr)
      } else {
        message.error({
          content: 'error',
          type: 'error'
        })
      }
    } catch (e) {
      message.error({
        content: e + '',
        type: 'error'
      })
    } finally {
      setIsloading(false)
    }
  }

  const getCategoriesData = async () => {
    const res = await traitService.getCategories(traitKey!)
    if (res.stat === 'ok') {
      setCategoriesData(res.data.rows)
      !category && setCategory(res.data.rows[0])
    }
  }

  const getTraitsData = async () => {
    const res = await traitService.getList(traitKey!, category)
    if (res.stat === 'ok') {
      setTraitsData(res.data.rows)
      console.log(traitId || res.data.rows[0].id)
      !traitId && setTraitId(res.data.rows[0].id)
      handleSubmit(traitId || res.data.rows[0].id)
    }
  }

  useEffect(() => {
    category && getTraitsData()
  }, [category])

  useEffect(() => {
    traitKey && getCategoriesData()
  }, [traitKey])

  useEffect(() => {
    if (history.location.state) {
      const { key, category, trait } = history.location.state as {
        key: traitType.Key
        category: string
        trait: string
      }

      setTraitKey(key)
      setCategory(category)
      setTraitId(trait)
    } else {
      setTraitKey('agronomic')
    }

    myChart.current = echarts.init(chartRef.current!, undefined, {
      renderer: 'svg'
    })
  }, [])

  return (
    <div className={styles.container}>
      <h1>GWAS</h1>
      <p></p>
      <div className={styles.content}>
        {/* <TVDInputContainer
          title="Reference Genome"
          component={
            <Radio.Group
              value={refGenome}
              onChange={e => setRefGenome(e.target.value)}
            >
              <Radio value={'SL4.0'}>SL4.0</Radio>
              <Radio value={'SL3.0'}>SL3.0</Radio>
              <Radio value={'SL2.50'}>SL2.50</Radio>
              <Radio value={'SL2.40'}>SL2.40</Radio>
            </Radio.Group>
          }
        /> */}
        <TVDInputContainer
          {...CONFIG}
          title="Variation Type"
          component={
            <Radio.Group
              value={variationType}
              onChange={e => setVariationType(e.target.value)}
            >
              <Radio value={'SNP'}>SNP</Radio>
              <Radio value={'InDel'}>InDel</Radio>
              <Radio value={'SV'}>SV</Radio>
              <Radio value={'PAV'}>PAV</Radio>
            </Radio.Group>
          }
        />
        <TVDInputContainer
          {...CONFIG}
          title="Model"
          component={
            <Radio.Group value={model} onChange={e => setModel(e.target.value)}>
              <Radio value={'GLM'}>GLM</Radio>
              <Radio value={'MLM'}>MLM</Radio>
              <Radio value={'CMLM'}>CMLM</Radio>
              <Radio value={'MLMM'}>MLMM</Radio>
            </Radio.Group>
          }
        />

        <TVDInputContainer
          {...CONFIG}
          title="Trait Database"
          component={
            <Select
              value={traitKey}
              onChange={(v: traitType.Key) => setTraitKey(v)}
            >
              <Option value="agronomic">Agronomic Traits</Option>
              <Option value="metabolic">Metabolic Traits</Option>
            </Select>
          }
        />
        <TVDInputContainer
          {...CONFIG}
          title="Category"
          component={
            <Select value={category} onChange={(v: string) => setCategory(v)}>
              {categoriesData.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          }
        />
        <TVDInputContainer
          {...CONFIG}
          title="Trait"
          component={
            <Select value={traitId} onChange={(v: string) => setTraitId(v)}>
              {traitsData.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.id} ({item.name})
                </Option>
              ))}
            </Select>
          }
        />
        <TVDChromosomeSelect
          {...CONFIG}
          value={chromosome}
          onChange={v => setChromosome(v)}
        />

        {chromosome !== 'all' && (
          <div className={styles.flex}>
            <Checkbox
              className={styles.check_box}
              defaultChecked={false}
              onChange={e => setChecked(e.target.checked)}
            />
            <TVDInputContainer
              {...CONFIG}
              title={<span className={styles.title}>Region</span>}
              component={
                <Input.Group compact>
                  <span className={styles.input_group_span}>Start</span>
                  <InputNumber
                    className={styles.input}
                    disabled={!checked}
                    style={{
                      width: 170,
                      textAlign: 'center'
                    }}
                    placeholder="Start Position"
                    min={0}
                    max={max}
                    addonAfter="Mb"
                    // controls={false}
                    value={min}
                    onChange={n => setMin(n)}
                  />
                  <span className={styles.input_group_span}>End</span>
                  <InputNumber
                    disabled={!checked}
                    className={'site-input-right' + styles.input}
                    style={{
                      width: 170,
                      textAlign: 'center'
                    }}
                    addonAfter="Mb"
                    // controls={false}
                    placeholder="End Position"
                    min={min ?? 0}
                    value={max}
                    onChange={n => setMax(n)}
                  />
                </Input.Group>
              }
            />
          </div>
        )}

        <div className={styles.btn_contaienr}>
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => handleSubmit(traitId)}
          >
            Submit
          </Button>
        </div>
      </div>

      {/* 展示 */}
      <div style={{ display: gwasData ? 'block' : 'none' }}>
        <div className={styles.images} style={{ textAlign: 'center' }}>
          <div className={styles.img}>
            <img width="100%" src={images?.manImage} />
            <span className={styles.imgSaveBtn}>
              Manhattan Plot
              <img
                src={saveSvg}
                onClick={() =>
                  downloadFile(images!.manImage, 'Manhattan_Plot.jpeg')
                }
              />
            </span>
          </div>
          <div className={styles.img}>
            <img width="100%" src={images?.qqImage} />
            <span className={styles.imgSaveBtn}>
              qq Plot
              <img
                src={saveSvg}
                onClick={() => downloadFile(images!.qqImage, 'qq_Plot.jpeg')}
              />
            </span>
          </div>
        </div>
        <div
          ref={chartRef}
          className={styles.chart}
          style={{ display: curChr !== 'all' ? 'block' : 'none' }}
        ></div>
        {/* <select
            className={styles.downloadType}
            value={downloadType}
            onChange={e =>
              setDownloadType(e.target.value as 'svg' | 'png' | 'jpeg' | 'webp')
            }
          >
            <option value="svg">svg</option>
            <option value="png">png</option>
            <option value="jpeg">jpeg</option>
            <option value="webp">webp</option>
          </select> */}

        {gwasData && (
          <div
            className={styles.tableBtn}
            style={{
              top: tableData.length > 0 ? '64px' : '0px',
              marginTop: tableData.length > 0 ? '0px' : '65px'
            }}
          >
            <Search
              placeholder="p-value阈值"
              enterButton="submit"
              size="middle"
              defaultValue={'5'}
              onSearch={v => {
                if (/^\d.?\d{0,}$/.test(v)) {
                  const pV = +v
                  if (curChr === 'all') {
                    if (pV < 5) {
                      message.info('不能小于5')
                      return
                    }
                  } else {
                    if (pV < 2) {
                      message.info('不能小于2')
                      return
                    }
                  }
                  filterTableData(gwasData, pV)
                }
              }}
            />
            <Button
              size="middle"
              onClick={() => {
                const title =
                  'Chromosome\tSL\tPosition\t-log10 (P-value)\tGene\tType\tAll\tSLL\tSLC\tGX\tSPIM\tWILD\n'
                const table = tableData
                  .map(item =>
                    [
                      item.chr,
                      item.refGenome,
                      item.position,
                      item.pValue,
                      item.gene,
                      item.type,
                      item.All,
                      item.SLL,
                      item.SLC,
                      item.GX,
                      item.SPIM,
                      item.WILD
                    ].join('\t')
                  )
                  .join('\n')

                downloadFile(
                  URL.createObjectURL(
                    new Blob([title + table], {
                      type: 'text/plain' + ';charset=' + 'utf-8'
                    })
                  ),
                  'p-value-table.txt'
                )
              }}
            >
              Download
            </Button>
          </div>
        )}
        <Table
          className={styles.table}
          size="small"
          columns={columns}
          dataSource={tableData}
          rowKey="id"
          bordered={true}
          sticky
          pagination={{
            position: ['topRight', 'bottomRight']
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="没有高于阈值的p-value"
              />
            )
          }}
        />
      </div>

      {/* 测试 */}
      {/* <div>
        <div ref={plotlyRef}></div>
      </div> */}
    </div>
  )
}

export default GWAS
