import { useEffect, useMemo, useRef, useState } from 'react'
import {
  TVDChromosomeSelect,
  TVDInputContainer,
  TVDMulSelect,
  TVDTextarea
} from '@/components'
import { Button, Input, InputNumber, message, Select, Table, Tabs } from 'antd'
import { expService } from '@/services'
import { commonType, gwasType } from '@/types'
import Plotly from 'plotly.js-dist-min'
import styles from './style.module.less'
import { downloadFile } from '@/utils'

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    key: 'index',
    width: 45
  },
  {
    title: 'Gene',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'FPKM',
    children: [
      {
        title: 'WILD',
        dataIndex: 'wild',
        key: 'wild',
        width: 100
      },
      {
        title: 'SP',
        dataIndex: 'sp',
        key: 'sp',
        width: 100
      },
      {
        title: 'SLC',
        dataIndex: 'slc',
        key: 'slc',
        width: 100
      },
      {
        title: 'SLL',
        dataIndex: 'sll',
        key: 'sll',
        width: 100
      }
    ]
  },
  {
    title: 'SLC_vs_SP',
    children: [
      {
        title: 'logFC',
        dataIndex: 'lcvspLogFC',
        key: 'lcvspLogFC',
        width: 100
      },
      {
        title: 'FDR',
        dataIndex: 'lcvspFDR',
        key: 'lcvspFDR',
        width: 100
      }
    ]
  },
  {
    title: 'SLC_vs_WILD',
    children: [
      {
        title: 'logFC',
        dataIndex: 'lcvldLogFC',
        key: 'lcvldLogFC',
        width: 100
      },
      {
        title: 'FDR',
        dataIndex: 'lcvldFDR',
        key: 'lcvldFDR',
        width: 100
      }
    ]
  },
  {
    title: 'SLL_vs_SLC',
    children: [
      {
        title: 'logFC',
        dataIndex: 'llvlcLogFC',
        key: 'llvlcLogFC',
        width: 100
      },
      {
        title: 'FDR',
        dataIndex: 'llvlcFDR',
        key: 'llvlcFDR',
        width: 100
      }
    ]
  },
  {
    title: 'SLL_vs_SP',
    children: [
      {
        title: 'logFC',
        dataIndex: 'llvspLogFC',
        key: 'llvspLogFC',
        width: 100
      },
      {
        title: 'FDR',
        dataIndex: 'llvspFDR',
        key: 'llvspFDR',
        width: 100
      }
    ]
  },
  {
    title: 'SLL_vs_WILD',
    children: [
      {
        title: 'logFC',
        dataIndex: 'llvldLogFC',
        key: 'llvldLogFC',
        width: 100
      },
      {
        title: 'FDR',
        dataIndex: 'llvldFDR',
        key: 'llvldFDR',
        width: 100
      }
    ]
  },
  {
    title: 'SP_vs_WILD',
    children: [
      {
        title: 'logFC',
        dataIndex: 'spvldLogFC',
        key: 'spvldLogFC',
        width: 100
      },
      {
        title: 'FDR',
        dataIndex: 'spvldFDR',
        key: 'spvldFDR',
        width: 100
      }
    ]
  }
]

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const Expression = () => {
  const { Option } = Select
  const { Search } = Input
  const { TabPane } = Tabs
  const heatmapRef = useRef<HTMLDivElement>(null)
  const boxPlotRef = useRef<HTMLDivElement>(null)
  const barPlotRef = useRef<HTMLDivElement>(null)
  // table
  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState<any[]>([])
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)
  const [limit, setLimit] = useState(10)
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState<string>()

  // region mode
  const [refGenome, setRefGenome] = useState<gwasType.RefGenome>('SL4.0')
  const [chr, setChr] = useState<gwasType.Chromosome>('ch00')
  const [start, setStart] = useState<number>()
  const [end, setEnd] = useState<number>()
  // IDs mode
  const [ids, setIds] = useState<string>('')
  // DEGs
  const [geneId, setGeneId] = useState<string>('')
  // common
  const [mode, setMode] = useState('ids')
  const [tissue, setTissue] = useState('pericarp')
  const [samples, setSamples] = useState<string>('')
  const [type, setType] = useState<commonType.Type>('Population')
  const [population, setPopulation] = useState<commonType.Population[]>([
    'SLL',
    'SLC',
    'SP',
    'WILD'
  ])
  const [samplesData, setSamplesData] = useState<
    { sample: string; type: string }[]
  >([])
  const [heatmapData, setHeatmapData] = useState<string[][]>()

  // const sampleOptions = useMemo(() => {
  //   return samplesData
  //     .filter(item => {
  //       return population.includes(item.type as commonType.Population)
  //     })
  //     .map(item => item.sample)
  // }, [population, samplesData])

  const getSamples = async () => {
    const res = await expService.getSamples()
    if (res.stat === 'ok') {
      setSamplesData(res.data)
    } else {
      console.log(res)
    }
  }

  const drawPlot = async (oridata: string[][], mode: string) => {
    const drawBoxPlot = async (oridata: string[][]) => {
      const data: Plotly.Data[] = oridata.slice(1).map(row => ({
        y: row.slice(1),
        type: 'box',
        name: row[0]
        // hoverongaps: false
      }))
      const layout: Partial<Plotly.Layout> = {
        title: 'Population Gene Expression Box Plot',
        annotations: [],
        xaxis: {
          ticks: '',
          side: 'bottom',
          automargin: true
          // tickangle: 60
        },
        yaxis: {
          side: 'left',
          type: 'category',
          automargin: true
          // tickangle: -45,
        }
      }
      const config: Partial<Plotly.Config> = {
        toImageButtonOptions: {
          filename: 'TVD_Population_Gene_Expression',
          format: 'svg'
        },
        displaylogo: false
      }

      await Plotly.newPlot(boxPlotRef.current!, data, layout, config)

      boxPlotRef.current?.scrollIntoView({
        behavior: 'smooth'
      })
    }

    if (oridata.length > 2) {
      const x = oridata[0].slice(1)
      const y = oridata.slice(1).map(array => array[0])
      const z = oridata.slice(1).map(array => {
        return array.slice(1).map(item => +item)
      })
      const data: Plotly.Data[] = [
        {
          z,
          x,
          y,
          type: 'heatmap'
          // hoverongaps: false
        }
      ]
      const layout: Partial<Plotly.Layout> = {
        title: 'Population Gene Expression Heatmap',
        annotations: [],
        xaxis: {
          ticks: '',
          side: 'bottom',
          automargin: true,
          showticklabels: false

          // tickangle: 60
        },
        yaxis: {
          side: 'left',
          type: 'category',
          automargin: true
          // tickangle: -45,
        }
      }
      const config: Partial<Plotly.Config> = {
        toImageButtonOptions: {
          filename: 'TVD_Population_Gene_Expression',
          format: 'svg'
        },
        displaylogo: false
      }
      const plotlyDiv = await Plotly.newPlot(
        heatmapRef.current!,
        data,
        layout,
        config
      )
      drawBoxPlot(oridata)
      heatmapRef.current!.scrollIntoView({
        behavior: 'smooth'
      })
    } else if (oridata.length === 2) {
      drawBoxPlot(oridata)
    }
  }

  const checkParams = () => {
    if (mode === 'ids') {
      if (!ids?.trim()) {
        message.error('ids')
        return false
      }
    } else if (mode === 'region') {
      if (typeof start !== 'number' || typeof end !== 'number') {
        message.error('region')
        return false
      }
    } else {
      if (!geneId?.trim()) {
        message.error('Gene 0ID')
        return false
      }
    }

    if (type === 'Individual') {
      if (!samples) {
        message.error('samples')
        return false
      } else {
        if (/[\\><()%$#@!?~`^&*+=[\]]/.test(samples)) {
          message.error('输入框存在违法符号')
          return false
        }
      }
    } else {
      if (!population.length) {
        message.error('population')
        return false
      }
    }

    return true
  }

  const handleSubmit = async (mode: string) => {
    setIsLoading(true)
    setHeatmapData(undefined)
    if (!checkParams()) {
      setIsLoading(false)
      return
    }
    message.info('提交成功，请稍候')
    const res =
      mode !== 'region'
        ? mode === 'ids'
          ? await expService.idsToHeatmap({
              ids: ids!,
              type,
              samples: samples.split(/[\n;,.]/),
              population,
              tissue
            })
          : await expService.idsToHeatmap({
              ids: geneId!.trim(),
              type,
              samples: samples.split(/[\n;,.]/),
              population: ['SLL', 'SLC', 'SP', 'WILD'],
              tissue
            })
        : await expService.regionToHeatmap({
            chr,
            type,
            samples: samples.split(/[\n;,.]/),
            tissue,
            population,
            start: start!,
            end: end!,
            refGenome
          })

    if (res.stat === 'ok') {
      if (mode === 'region' && res.data.data.length <= 1) {
        message.error('Interval is too short')
      }
      setHeatmapData(res.data.data)
      drawPlot(res.data.data, mode)
    }
    setIsLoading(false)
  }

  const getTableData = async () => {
    setLoading(true)
    const res = await expService.getTableData({
      start: (current - 1) * limit,
      limit,
      keyword
    })

    if (res.stat === 'ok') {
      setTableData(res.data.rows)
      setTotal(res.data.total)
    } else {
      message.error('Error')
    }
    setLoading(false)
  }

  useEffect(() => {
    setCurrent(1)
    setTableData([])
  }, [limit, keyword])

  useEffect(() => {
    getTableData()
  }, [current, limit, keyword])

  useEffect(() => {
    getTableData()
  }, [])

  return (
    <div className={styles.container}>
      <h1>Population Gene Expression</h1>
      <p></p>

      <div className={styles.content}>
        <Tabs
          className={styles.tabs}
          onChange={key => setMode(key)}
          type="card"
        >
          <TabPane tab="Input IDs" key="ids">
            <TVDTextarea
              {...CONFIG}
              title="Gene IDs"
              placeholder="Please input Gene IDs"
              example="Try Example"
              value={ids}
              height={200}
              onChange={v => setIds(v)}
              setExample={() =>
                setIds(
                  'Solyc12g100270\nSolyc12g100280\nSolyc12g100290\nSolyc12g100300\nSolyc12g100310\nSolyc12g100320\nSolyc12g100330\nSolyc12g100340\nSolyc12g100350\nSolyc12g100360'
                )
              }
            />
          </TabPane>
          <TabPane tab="By Region" key="region">
            <TVDChromosomeSelect
              {...CONFIG}
              options={[
                'ch00',
                'ch01',
                'ch02',
                'ch03',
                'ch04',
                'ch05',
                'ch06',
                'ch07',
                'ch08',
                'ch09',
                'ch10',
                'ch11',
                'ch12'
              ]}
              value={chr}
              onChange={v => setChr(v)}
            />

            <TVDInputContainer
              {...CONFIG}
              title="Region (bp)"
              component={
                <Input.Group compact>
                  <span className={styles.input_group_span}>Start</span>
                  <InputNumber
                    className={styles.groupInputNumber}
                    placeholder="Start Position"
                    value={start}
                    min={1}
                    onChange={start => setStart(start)}
                  />
                  <span className={styles.input_group_span}>End</span>
                  <InputNumber
                    className={styles.groupInputNumber}
                    placeholder="End Position"
                    min={start ?? 1}
                    max={start ? start + 1000000 : 1000000}
                    value={end}
                    onChange={end => setEnd(end)}
                  />
                </Input.Group>
              }
              afterComponent={
                <span>
                  <span>
                    eg:{' '}
                    <span className="after_eg" onClick={() => {}}>
                      2000-20000
                    </span>
                    , input a range (&lt; 100 kb)
                  </span>
                </span>
              }
            />
          </TabPane>
          <TabPane tab="DEGs" key="DEGs">
            <TVDInputContainer
              {...CONFIG}
              title={'Gene ID'}
              component={
                <Input
                  value={geneId}
                  onChange={e => setGeneId(e.target.value)}
                />
              }
            />
          </TabPane>
        </Tabs>

        {mode !== 'DEGs' && (
          <>
            <TVDInputContainer
              {...CONFIG}
              title="Tissue"
              type="normal"
              component={
                <Select value={tissue} onChange={(v: string) => setTissue(v)}>
                  <Option value="pericarp" key="pericarp">
                    pericarp
                  </Option>
                </Select>
              }
            />

            <TVDInputContainer
              {...CONFIG}
              title={
                <Select
                  className={styles.titleComponent}
                  value={type}
                  onChange={(v: commonType.Type) => setType(v)}
                >
                  <Option value="Population">Population</Option>
                  <Option value="Individual">Individual</Option>
                </Select>
              }
              afterComponent={
                type === 'Individual' ? (
                  <span>
                    <span
                      className="after_eg"
                      onClick={() => {
                        setSamples('ts-100\nts-101\nts-103')
                      }}
                    >
                      Try example
                    </span>
                  </span>
                ) : undefined
              }
              component={
                type === 'Population' ? (
                  <Select
                    // placeholder={placeholder}
                    optionLabelProp="label"
                    mode="multiple"
                    value={population}
                    onChange={vals => setPopulation(vals)}
                  >
                    <Option value="SLL">SLL</Option>
                    <Option value="SLC">SLC</Option>
                    <Option value="SP">SP</Option>
                    <Option value="WILD">WILD</Option>
                  </Select>
                ) : (
                  <div>
                    <TVDTextarea
                      {...CONFIG}
                      placeholder="Please input Accession"
                      example="Try Example"
                      value={samples}
                      allowClear={true}
                      height={200}
                      onChange={v => setSamples(v)}
                    />
                  </div>
                )
              }
            />

            {/* <TVDMulSelect
              width={1060}
              titlewidth={160}
              title="Samples"
              options={sampleOptions}
              placeholder="Select Samples"
              value={samples}
              allowClear
              maxTagCount={30}
              onChange={v => setSamples(v)}
            /> */}
          </>
        )}

        <div className={styles.btn_contaienr}>
          <Button
            type="primary"
            loading={isLoading}
            onClick={() => handleSubmit(mode)}
          >
            Submit
          </Button>
        </div>

        {mode === 'DEGs' && (
          <div
            className={styles.table_default}
            // style={{ display: plotData?.length ? 'block' : 'none' }}
          >
            <div className={styles.tableBtn}>
              <Search
                placeholder="Gene ID"
                enterButton="Search"
                size="middle"
                onSearch={keyword => setKeyword(keyword)}
              />
              <Button
                size="middle"
                // onClick={() => {
                //   const title = `Accession\tGroup\tTaxon\t${valTypes
                //     .map(
                //       (_, i) =>
                //         `${trait?.id}${valTypes.length > 1 ? '-' + (i + 1) : ''}`
                //     )
                //     .join('\t')}\n`

                //   const table = tableData
                //     ?.map(item =>
                //       [
                //         item.accession,
                //         item.group,
                //         item.taxon,
                //         ...valTypes.map((_, i) => item[`value${i + 1}`])
                //       ].join('\t')
                //     )
                //     .join('\n')

                //   downloadFile(
                //     URL.createObjectURL(
                //       new Blob([title + table], {
                //         type: 'text/plain' + ';charset=' + 'utf-8'
                //       })
                //     ),
                //     `${category}_${trait?.category}_${trait?.id}.txt`
                //   )
                // }}
              >
                Download
              </Button>
            </div>

            <Table
              sticky
              className={styles.table}
              columns={columns}
              dataSource={tableData}
              loading={loading}
              size="small"
              rowKey="id"
              scroll={{ x: 1800 }}
              bordered={true}
              pagination={{
                total,
                current,
                pageSize: limit,
                position: ['topLeft', 'bottomLeft']
              }}
              onChange={pagination => {
                ;(pagination.pageSize as number) === limit
                  ? setCurrent(pagination.current as number)
                  : setCurrent(1)
                setLimit(pagination.pageSize as number)
              }}
            />
          </div>
        )}

        <div className={styles.results}>
          <div
            style={{
              display: heatmapData && heatmapData.length > 1 ? 'block' : 'none'
            }}
          >
            <Button
              onClick={() => {
                downloadFile(
                  URL.createObjectURL(
                    new Blob(
                      [heatmapData!.map(row => row.join('\t'))?.join('\n')],
                      {
                        type: 'text/plain' + ';charset=' + 'utf-8'
                      }
                    )
                  ),
                  'Population_Gene_Expression-table.txt'
                )
              }}
            >
              Download Table
            </Button>
            {mode !== 'DEGs' && <div ref={heatmapRef}></div>}
            <div ref={boxPlotRef}></div>
            {mode === 'DEGs' && <div ref={barPlotRef}></div>}
            {/* <iframe
              src="http://123.60.14.43/corplott/"
              width={1200}
              height={1200}
              frameBorder="no"
            ></iframe> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Expression
