import request, { BaseRes } from '@/libs/request'
import { traitType } from '../types'

const PREFIX = '/api/trait'

export const getCategories = async (key: traitType.Key) => {
  const { data } = await request.post<BaseRes<traitType.CategoryRes>>(PREFIX + '/categories', { key })
  return data
}

export const getList = async (key: traitType.Key, category?: string) => {
  const { data } = await request.post<BaseRes<traitType.TraitListRes>>(PREFIX + '/list', {
    key,
    category
  })
  return data
}

export const getData = async (key: traitType.Key, trait: string) => {
  const { data } = await request.post<BaseRes<traitType.TraitDataRes>>(PREFIX + '/freq', {
    key,
    trait
  })
  return data
}
