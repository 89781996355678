import styles from './style.module.less'

const Tutorial = () => {
  return (
    <div className={styles.container}>
      <h1>Tutorial</h1>
    </div>
  )
}

export default Tutorial
