import { useEffect, useState } from 'react'
import { Input, InputNumber, message, Radio, Select } from 'antd'
import { TVDChromosomeSelect, TVDInputContainer } from '@/components'
import { gwasType } from '@/types'
import styles from './style.module.less'

type Type = 'Population' | 'Individual'

export interface RegionParams {
  type: Type
  chromosome: string
  start: number
  end: number
  population1: string
  population2: string
  accession1: string
  accession2: string
}

interface Props {
  setParams: (params: RegionParams) => void
}

const chr: gwasType.Chromosome[] = [
  'ch00',
  'ch01',
  'ch02',
  'ch03',
  'ch04',
  'ch05',
  'ch06',
  'ch07',
  'ch08',
  'ch09',
  'ch10',
  'ch11',
  'ch12'
]

const CONFIG = {
  pd: {
    width: 480,
    titlewidth: 280
  },
  pdpro: {
    width: 'auto',
    titlewidth: 280
  }
}

const PDRegion = (props: Props) => {
  const { Option } = Select
  const [refGenome, setRefGenome] = useState('SL4.0')
  const [variationType, setVariationType] = useState('Total')
  const [type, setType] = useState<Type>('Individual')
  const [chromosome, setChromosome] = useState<gwasType.Chromosome>('ch01')
  const [start, setStart] = useState(1000)
  const [end, setEnd] = useState(2000)
  const [population1, setPopulation1] = useState('TS1')
  const [population2, setPopulation2] = useState('TS18')
  const [accession1, setAccession1] = useState('')
  const [accession2, setAccession2] = useState('')
  const [fst, setFst] = useState('0.8')

  useEffect(() => {
    props.setParams({
      type,
      chromosome,
      start,
      end,
      population1,
      population2,
      accession1,
      accession2
    })
  }, [
    type,
    chromosome,
    start,
    end,
    population1,
    population2,
    accession1,
    accession2
  ])

  return (
    <div className={styles.region_container}>
      <h2>Primer Design by Region</h2>
      <div className={styles.region_content}>
        {/* <TVDInputContainer
          type="PDpro"
          title="Reference Genome"
          component={
            <Radio.Group
              value={refGenome}
              onChange={e => setRefGenome(e.target.value)}
            >
              <Radio value={'SL4.0'}>SL4.0</Radio>
              <Radio value={'SL3.0'}>SL3.0</Radio>
              <Radio value={'SL2.50'}>SL2.50</Radio>
              <Radio value={'SL2.40'}>SL2.40</Radio>
            </Radio.Group>
          }
        /> */}
        <TVDInputContainer
          {...CONFIG.pdpro}
          title="Variation Type"
          component={
            <Radio.Group
              value={variationType}
              onChange={e => setVariationType(e.target.value)}
            >
              <Radio value={'Total'}>Total</Radio>
              <Radio value={'SNP'}>SNP</Radio>
              <Radio value={'InDel'}>InDel</Radio>
              <Radio value={'CNV'}>CNV</Radio>
              <Radio value={'SV'}>SV</Radio>
            </Radio.Group>
          }
        />

        <TVDChromosomeSelect
          {...CONFIG.pdpro}
          width={817}
          options={chr}
          value={chromosome}
          onChange={v => setChromosome(v)}
        />

        <TVDInputContainer
          {...CONFIG.pdpro}
          title="Region (bp)"
          component={
            <Input.Group compact>
              <span className={styles.input_group_span}>Start</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Start Position"
                min={0}
                max={end}
                value={start}
                onChange={n => setStart(n)}
              />
              <span className={styles.input_group_span}>End</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="End Position"
                min={start ?? 0}
                value={end}
                onChange={n => setEnd(n)}
              />
            </Input.Group>
          }
        />

        <TVDInputContainer
          {...CONFIG.pdpro}
          title={
            <Select
              className={styles.titleComponent}
              value={type}
              onChange={(v: Type) => setType(v)}
            >
              <Option value="Individual">Individual</Option>
              <Option value="Population">Population</Option>
            </Select>
          }
          component={
            type !== 'Population' ? (
              <Input.Group compact>
                <span className={styles.input_group_span}>Accession1</span>
                <Input
                  className={styles.groupInputString}
                  value={accession1}
                  placeholder="TS1"
                  onChange={e => setAccession1(e.target.value)}
                />
                <span className={styles.input_group_span}>Accession2</span>
                <Input
                  className={styles.groupInputString}
                  value={accession2}
                  placeholder="TS13"
                  onChange={e => setAccession2(e.target.value)}
                />
              </Input.Group>
            ) : (
              <Input.Group compact>
                <span className={styles.input_group_span}>Population1</span>
                <Select
                  className={styles.groupInputString}
                  value={population1}
                  onChange={(v: string) => setPopulation1(v)}
                >
                  <Option value="TS1">TS1</Option>
                  <Option value="TS9">TS9</Option>
                  <Option value="TS18">TS18</Option>
                </Select>
                <span className={styles.input_group_span}>Population2</span>
                <Select
                  className={styles.groupInputString}
                  value={population2}
                  onChange={(v: string) => setPopulation2(v)}
                >
                  <Option value="TS1">TS1</Option>
                  <Option value="TS9">TS9</Option>
                  <Option value="TS18">TS18</Option>
                </Select>
                <span className={styles.input_group_span}>Fst</span>
                <Select
                  className={styles.groupInputString}
                  value={fst}
                  onChange={(v: string) => setFst(v)}
                >
                  <Option value="0.8">0.8</Option>
                  <Option value="0.85">0.85</Option>
                  <Option value="0.9">0.9</Option>
                  <Option value="0.95">0.95</Option>
                  <Option value="1.0">1.0</Option>
                </Select>
              </Input.Group>
            )
          }
        />
      </div>
    </div>
  )
}

export default PDRegion
