import { ChangeEvent, useState } from 'react'
import {
  TVDChromosomeSelect,
  TVDInputContainer,
  TVDMulSelect
} from '@/components'
import {
  Radio,
  Input,
  message,
  Button,
  Select,
  RadioChangeEvent,
  Space,
  Tabs,
  InputNumber
} from 'antd'
import styles from './style.module.less'
import { commonType } from '@/types'

const popOptions = ['SP_vs_SLC', 'SLC_vs_SLL']

const chromosomeData: commonType.Chromosome[] = [
  'ch00',
  'ch01',
  'ch02',
  'ch03',
  'ch04',
  'ch05',
  'ch06',
  'ch07',
  'ch08',
  'ch09',
  'ch10',
  'ch11',
  'ch12'
]

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const SelectionSignals = () => {
  const { Option } = Select
  const { TabPane } = Tabs
  const [type, setType] = useState('region')
  const [population, setPopulation] = useState<string[]>([...popOptions])
  const [start, setStart] = useState<number>()
  const [end, setEnd] = useState<number>()

  const handleValueChange = (
    e: ChangeEvent<HTMLInputElement> | RadioChangeEvent
  ) => {
    message.info('你选择了' + e.target.value)
  }

  return (
    <div className={styles.container}>
      <h1>Selection Signals</h1>
      <p></p>
      <div className={styles.content}>
        {/* <TVDInputContainer
          title="Reference Genome"
          component={
            <Radio.Group onChange={handleValueChange}>
              <Radio value={'SL4.0'}>SL4.0</Radio>
              <Radio value={'SL3.0'}>SL3.0</Radio>
              <Radio value={'SL2.50'}>SL2.50</Radio>
              <Radio value={'SL2.40'}>SL2.40</Radio>
            </Radio.Group>
          }
        /> */}

        <Tabs
          className={styles.tabs}
          onChange={key => {
            setType(key)
          }}
          type="card"
        >
          <TabPane tab="By Region" key="region">
            <TVDChromosomeSelect options={chromosomeData} {...CONFIG} />

            <TVDInputContainer
              {...CONFIG}
              title={<span className={styles.title}>Region</span>}
              component={
                <Input.Group compact>
                  <span className={styles.input_group_span}>Start</span>
                  <InputNumber
                    className={styles.groupInputNumber}
                    placeholder="Start Position"
                    min={0}
                    max={end}
                    value={start}
                    onChange={n => setStart(n)}
                  />
                  <span className={styles.input_group_span}>End</span>
                  <InputNumber
                    className={styles.groupInputNumber}
                    placeholder="Start Position"
                    min={start ?? 0}
                    value={end}
                    onChange={n => setEnd(n)}
                  />
                </Input.Group>
              }
              afterComponent={
                <span>
                  <span>
                    eg:{' '}
                    <span
                      className="after_eg"
                      onClick={() => {
                        setStart(2000)
                        setEnd(20000)
                      }}
                    >
                      2000-20000
                    </span>
                    , input a range (&lt; 100 kb)
                  </span>
                  45{' '}
                </span>
              }
            />
            <TVDMulSelect
              {...CONFIG}
              options={popOptions}
              value={population}
              onChange={v => setPopulation(v)}
            />
          </TabPane>

          <TabPane tab="By Gene" key="geneId">
            <TVDInputContainer
              {...CONFIG}
              title={<span className={styles.title}>Gene ID</span>}
              component={<Input />}
            />
            <TVDMulSelect
              {...CONFIG}
              options={popOptions}
              value={population}
              onChange={v => setPopulation(v)}
            />
          </TabPane>
        </Tabs>

        <div className={styles.btn_contaienr}>
          <Button
            type="primary"
            onClick={() => {
              message.info('你点击了提交按钮')
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SelectionSignals
