import request, { BaseRes } from '@/libs/request'
import { blastType } from '../types'

const PREFIX = '/api/blast'

export const blastSeq = async (body: blastType.IBlastBody) => {
  const { data } = await request.post<BaseRes<blastType.IBlastResponse>>(PREFIX + '/blast', body)
  return data
}

