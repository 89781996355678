import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { TVDInputContainer } from '..'
import { gwasType } from '@/types'

interface Props {
  value?: gwasType.Chromosome
  type?: 'normal' | 'PD'
  onChange?: (v: gwasType.Chromosome) => void
  options?: gwasType.Chromosome[]
  className?: string
  width?: string | number
  titlewidth?: string | number
  prefix?: boolean
}

const chromosomeData: gwasType.Chromosome[] = [
  'all',
  'ch00',
  'ch01',
  'ch02',
  'ch03',
  'ch04',
  'ch05',
  'ch06',
  'ch07',
  'ch08',
  'ch09',
  'ch10',
  'ch11',
  'ch12'
]

const TVDChromosomeSelect = (props: Props) => {
  const { Option } = Select
  const {
    options = chromosomeData,
    value = options[0],
    prefix = true,
    ...Itemprops
  } = props
  const [checked, setChecked] = useState<gwasType.Chromosome>(value)

  useEffect(() => {
    props.onChange?.(checked)
  }, [checked])

  return (
    <TVDInputContainer
      title="Chromosome"
      type={props.type}
      classname={props.className}
      {...Itemprops}
      component={
        <Select
          value={checked}
          onChange={(v: gwasType.Chromosome) => setChecked(v)}
        >
          {options.map(chr => (
            <Option key={chr} value={chr}>
              {prefix ? (chr === 'all' ? 'all' : 'SL4.0' + chr) : chr}
            </Option>
          ))}
        </Select>
      }
    />
  )
}

export default TVDChromosomeSelect
