import { Fragment, useEffect, useRef } from 'react'
import imgRoutes from './imgRoutes'
import Card from './Card'
import styles from './style.module.less'

const Home = () => {
  const lazyLoading = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    lazyLoading.current = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= 0.1) {
          const target = entry.target
          ; (target as any).src = target.getAttribute('data-src')
          lazyLoading.current!.unobserve(target)
        }
      })
    }, {
      threshold: 0.1
    })
  }, [])
  return (
    <>
      {/* <Carousel autoplay className={styles.carousel}>
        <img src={homeImg1} alt="" />
        <img src={homeImg2} alt="" />
        <img src={homeImg3} alt="" />
      </Carousel> */}

      <div className={styles.container}>
        <div className={styles.typography}>
          <h1>TVD</h1>
          <p style={{ textIndent: '1em', fontSize: '20px' }}>
            TVD is a comprehensive database for tomato genomic variation. It
            offers not only variant retrieval, marker and primer design, but
            also retrieval of GWAS results of over 700 traits. It also includes
            population transcriptome-based expression, eQTL, sQTL retrieval,
            etc. In addition, the database also provides pan-genome sequences of
            836 tomato accessions and pan-transcriptome sequences of 405
            transcriptomes.
          </p>

          <div className={styles.modules}>
            <div>
              <h2>Search Module</h2>
              <div>
                {imgRoutes.search.map(imgRoute =>
                  <Fragment key={imgRoute.route}>
                    <Card {...imgRoute} observer={lazyLoading.current!} />
                  </Fragment>)}
              </div>
            </div>
            <div>
              <h2>Pan-genome Module</h2>
              <div>
                {imgRoutes.pangenome.map(imgRoute =>
                  <Fragment key={imgRoute.route}>
                    <Card {...imgRoute} observer={lazyLoading.current!} />
                  </Fragment>)}
              </div>
            </div>
            <div>
              <h2>Tools Module</h2>
              <div>
                {imgRoutes.tools.map(imgRoute =>
                  <Fragment key={imgRoute.route}>
                    <Card {...imgRoute} observer={lazyLoading.current!} />
                  </Fragment>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
