import { useState, useEffect } from 'react'
import { Input, InputNumber, Checkbox, Select, Switch, Tooltip } from 'antd'
import { TVDInputContainer } from '@/components'
import statList from '@/libs/stat'
import styles from './style.module.less'

interface BlastParams {
  eValue: string | number
  wordSize: number
  minIdentity: number
  database: string
}

export interface CommonParams {
  optSize: number
  maxSize: number
  minSize: number
  optTm: number
  maxTm: number
  minTm: number
  optGC: number
  maxGC: number
  minGC: number
  productMinSize: number
  productMaxSize: number
  numberOfPrimer: number
  max3Stability: number
  tmMaxDiff: number
  maxPolyX: number
  maxGC3End: number
  endDistanceLeft: number
  endDistanceRight: number
  maxSelf: number
  maxPair: number
  max3Self: number
  max3Pair: number
  maxPrimer: number
  blastPramas?: BlastParams
}

interface Props {
  setParams: (params: CommonParams) => void
  templateLen?: number
}

const parameters = [
  'Optimal primer size',
  'Max primer size',
  'Min primer size',
  'Optimal primer TM',
  'Max primer TM',
  'Min primer TM',
  'Optimal GC percent',
  'Max GC percent',
  'Min GC percent',
  'Default product',
  'Max Poly-X',
  'Max Self Any'
]

const CONFIG = {
  pd: {
    width: 480,
    titlewidth: 280
  },
  pdpro: {
    width: 'auto',
    titlewidth: 280
  },
  blast: {
    width: 280 + 537,
    titlewidth: 280
  }
}

const Parameter = (props: Props) => {
  const { Option } = Select
  const [params, setParams] = useState<CommonParams>({
    optSize: 20,
    maxSize: 23,
    minSize: 18,
    optTm: 59.0,
    maxTm: 62.0,
    minTm: 57.0,
    optGC: 50.0,
    maxGC: 70.0,
    minGC: 30.0,
    productMinSize: 100,
    productMaxSize: 300,
    numberOfPrimer: 5,
    max3Stability: 9.0,
    tmMaxDiff: 5.0,
    maxPolyX: 4,
    maxGC3End: 5,
    endDistanceLeft: 3,
    endDistanceRight: 3,
    maxSelf: 45.0,
    maxPair: 45.0,
    max3Self: 35.0,
    max3Pair: 35.0,
    maxPrimer: 24.0
  })
  const [checked, setChecked] = useState(false)
  const [blastParams, setBlastParams] = useState<BlastParams>({
    eValue: 30000,
    wordSize: 7,
    minIdentity: 60,
    database: 'SL4.0'
  })

  useEffect(() => {
    const param = checked ? { ...params, ...blastParams } : params
    props.setParams(param)
  }, [params])

  useEffect(() => {
    if (params.productMinSize > params.productMaxSize) {
      setParams({ ...params, productMaxSize: params.productMinSize })
    }
    if (params.productMaxSize - params.productMinSize > 200) {
      setParams({ ...params, productMaxSize: params.productMinSize + 200 })
    }
  }, [params.productMinSize])

  useEffect(() => {
    if (props?.templateLen && props.templateLen < params.productMaxSize) {
      setParams({ ...params, productMaxSize: props.templateLen })
    }
  }, [params.productMaxSize])

  return (
    <div className={styles.param_contaienr}>
      <h2>Basic Settings</h2>
      <div className={styles.param_content}>
        <TVDInputContainer
          title="Primer Size (bp) "
          {...CONFIG.pdpro}
          component={
            <Input.Group compact>
              <span className={styles.input_group_span}>Min</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Min"
                min={0}
                value={params.minSize}
                onChange={minSize => setParams({ ...params, minSize })}
              />
              <span className={styles.input_group_span}>Opt</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Opt"
                min={params.minSize ?? 0}
                value={params.optSize}
                onChange={optSize => setParams({ ...params, optSize })}
              />
              <span className={styles.input_group_span}>Max</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Max"
                min={params.minSize ?? 0}
                value={params.maxSize}
                onChange={maxSize => setParams({ ...params, maxSize })}
              />
            </Input.Group>
          }
        />
        <TVDInputContainer
          {...CONFIG.pdpro}
          title="Primer GC (%)"
          component={
            <Input.Group compact>
              <span className={styles.input_group_span}>Min</span>
              <InputNumber
                className={styles.groupInputNumber}
                step={0.1}
                placeholder="Min"
                min={0}
                value={params.minGC}
                onChange={minGC => setParams({ ...params, minGC })}
              />
              <span className={styles.input_group_span}>Opt</span>
              <InputNumber
                className={styles.groupInputNumber}
                step={0.1}
                placeholder="Opt"
                value={params.optGC}
                onChange={optGC => setParams({ ...params, optGC })}
              />
              <span className={styles.input_group_span}>Max</span>
              <InputNumber
                className={styles.groupInputNumber}
                step={0.1}
                placeholder="Max"
                // min={min ?? 0}
                value={params.maxGC}
                onChange={maxGC => setParams({ ...params, maxGC })}
              />
            </Input.Group>
          }
        />
        <TVDInputContainer
          {...CONFIG.pdpro}
          title="Primer Tm (°C)"
          component={
            <Input.Group compact>
              <span className={styles.input_group_span}>Min</span>
              <InputNumber
                className={styles.groupInputNumber}
                step={0.1}
                placeholder="Min"
                min={0}
                value={params.minTm}
                onChange={minTm => setParams({ ...params, minTm })}
              />
              <span className={styles.input_group_span}>Opt</span>
              <InputNumber
                className={styles.groupInputNumber}
                step={0.1}
                placeholder="Opt"
                // min={min ?? 0}
                value={params.optTm}
                onChange={optTm => setParams({ ...params, optTm })}
              />
              <span className={styles.input_group_span}>Max</span>
              <InputNumber
                className={styles.groupInputNumber}
                step={0.1}
                placeholder="Max"
                min={params.minTm ?? 0}
                value={params.maxTm}
                onChange={maxTm => setParams({ ...params, maxTm })}
              />
            </Input.Group>
          }
        />

        <TVDInputContainer
          {...CONFIG.pdpro}
          title="Product Size Range (bp)"
          component={
            <Input.Group compact>
              <span className={styles.input_group_span}>Min</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Min"
                min={100}
                max={props?.templateLen}
                value={params.productMinSize}
                onChange={productMinSize =>
                  setParams({ ...params, productMinSize })
                }
              />
              <span className={styles.input_group_span}>Max</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Max"
                min={params.productMinSize}
                max={
                  props?.templateLen
                    ? params.productMinSize + 200 > props.templateLen
                      ? props.templateLen
                      : params.productMinSize + 200
                    : params.productMinSize + 200
                }
                value={params.productMaxSize}
                onChange={productMaxSize =>
                  setParams({ ...params, productMaxSize })
                }
              />
            </Input.Group>
          }
        />

        <div className={styles.one}>
          <TVDInputContainer
            {...CONFIG.pd}
            title="Number of primers"
            component={
              <InputNumber
                value={params.numberOfPrimer}
                onChange={numberOfPrimer =>
                  setParams({ ...params, numberOfPrimer })
                }
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.pd}
            title="Max 3' Stability"
            component={
              <InputNumber
                step={0.1}
                value={params.max3Stability}
                onChange={max3Stability =>
                  setParams({ ...params, max3Stability })
                }
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.pd}
            title="Primer Pair Tm Max Difference (°C)"
            component={
              <InputNumber
                step={0.1}
                value={params.tmMaxDiff}
                onChange={tmMaxDiff => setParams({ ...params, tmMaxDiff })}
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.pd}
            title="Max GC in primer 3' end"
            component={
              <InputNumber
                value={params.maxGC3End}
                onChange={maxGC3End => setParams({ ...params, maxGC3End })}
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.pd}
            title="Max Poly-X"
            component={
              <InputNumber
                value={params.maxPolyX}
                onChange={maxPolyX => setParams({ ...params, maxPolyX })}
              />
            }
          />
        </div>

        <TVDInputContainer
          {...CONFIG.pdpro}
          title="3' End Distance Between"
          component={
            <Input.Group compact>
              <span className={styles.input_group_span}>Left Primers</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Right Primers"
                min={0}
                value={params.endDistanceLeft}
                onChange={endDistanceLeft =>
                  setParams({ ...params, endDistanceLeft })
                }
              />
              <span className={styles.input_group_span}>Right Primers</span>
              <InputNumber
                className={styles.groupInputNumber}
                placeholder="Right Primers"
                // min={min ?? 0}
                value={params.endDistanceRight}
                onChange={endDistanceRight =>
                  setParams({ ...params, endDistanceRight })
                }
              />
            </Input.Group>
          }
        />
      </div>

      <h2>Thermodynamic Secondary Structure Alignments</h2>
      <div className={styles.param_content}>
        <div className={styles.one}>
          <TVDInputContainer
            {...CONFIG.pd}
            title="TH: Max Self Complementarity"
            component={
              <InputNumber
                step={0.1}
                value={params.maxSelf}
                onChange={maxSelf => setParams({ ...params, maxSelf })}
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.pd}
            title="TH: Max Pair Complementarity"
            component={
              <InputNumber
                step={0.1}
                value={params.maxPair}
                onChange={maxPair => setParams({ ...params, maxPair })}
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.pd}
            title="TH: Max 3' Self Complementarity"
            component={
              <InputNumber
                step={0.1}
                value={params.max3Self}
                onChange={max3Self => setParams({ ...params, max3Self })}
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.pd}
            title="TH: Max 3' Pair Complementarity"
            component={
              <InputNumber
                step={0.1}
                value={params.max3Pair}
                onChange={max3Pair => setParams({ ...params, max3Pair })}
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.pd}
            title="TH: Max Primer Hairpin"
            component={
              <InputNumber
                step={0.1}
                value={params.maxPrimer}
                onChange={maxPrimer => setParams({ ...params, maxPrimer })}
              />
            }
          />
        </div>
      </div>

      <h2 className={styles.vertical}>
        <Tooltip overlay="选择此项将会对生成的引物blast">
          Primer-Blast
          <Switch
            style={{ marginLeft: '10px' }}
            checked={checked}
            onChange={checked => setChecked(checked)}
          />
        </Tooltip>
      </h2>
      {checked && (
        <div>
          <TVDInputContainer
            {...CONFIG.blast}
            title="E-value"
            component={
              <Input
                value={blastParams.eValue}
                onChange={e => {
                  setBlastParams({ ...blastParams, eValue: e.target.value })
                }}
              />
            }
          />

          <TVDInputContainer
            {...CONFIG.blast}
            title="Word Size"
            component={
              <InputNumber
                value={blastParams.wordSize}
                min={5}
                // max={/(tblastn|blastp|blastx)/.test(program) ? 8 : 16}
                onChange={wordSize =>
                  setBlastParams({ ...blastParams, wordSize })
                }
              />
            }
          />
          <TVDInputContainer
            {...CONFIG.blast}
            title="Min. Identity (%)"
            component={
              <InputNumber
                value={blastParams.minIdentity}
                min={5}
                // max={/(tblastn|blastp|blastx)/.test(program) ? 8 : 16}
                onChange={minIdentity =>
                  setBlastParams({ ...blastParams, minIdentity })
                }
              />
            }
          />

          <TVDInputContainer
            {...CONFIG.blast}
            title="Selected Databases"
            component={
              <Select
                value={blastParams.database}
                onChange={(database: string) =>
                  setBlastParams({ ...blastParams, database })
                }
              >
                {/* <Option value="Pan-genome">Pan-genome</Option> */}
                <Option value="SL4.0">Tomato Genome (SL4.0)</Option>
                <Option value="SL3.0">Tomato Genome (SL3.0)</Option>
                <Option value="SL2.50">Tomato Genome (SL2.50)</Option>
                <Option value="SL2.40">Tomato Genome (SL2.40)</Option>
              </Select>
            }
          />
        </div>
      )}
    </div>
  )
}

export default Parameter
