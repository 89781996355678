import request, { BaseRes } from '@/libs/request'
import { variationType } from '../types'

const PREFIX = '/api/variation'

export const getVariationData = async () => {
  const { data } = await request.get<BaseRes<variationType.VariationRes>>(PREFIX + '/list')
  return data
}

