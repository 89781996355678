import { useState, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, message, Tabs } from 'antd'
import Parameter, { CommonParams } from './Parameter'
import PDRegion, { RegionParams } from './PDRegion'
import PDGeneral, { GeneralParams } from './PDGeneral'
import PDVariation, { VaraIdParams } from './PDVariation'
import { checkParams, downloadFile, checkStat } from '@/utils'
import { primerService } from '@/services'
import { primerType } from '@/types'
import Table, { ColumnsType } from 'antd/lib/table'
import styles from './style.module.less'

const PrimerDesign = () => {
  const { TabPane } = Tabs
  const history = useHistory()
  // index 页面使用
  const resultDivRef = useRef<HTMLDivElement>(null)
  const matchPreRef = useRef<HTMLPreElement>(null)
  const [isLoading, setIsloading] = useState(false)
  const [primerData, setPrimerData] = useState<primerType.IPrimerItem[]>()
  const [matchSeq, setMatchSeq] = useState('')
  // 联动使用
  const [templateLen, setTemplateLen] = useState<number>()
  // 获取组件参数
  const [designType, setDesignType] = useState('region')
  const [commonparams, setCommonParams] = useState<CommonParams>()
  const [regionParams, setRegionParams] = useState<RegionParams>()
  const [variationParams, setVariationParams] = useState<VaraIdParams>()
  const [generalParams, setGeneralParams] = useState<GeneralParams>()

  const checkGeneralSeqLen = () => {
    let len: number | undefined
    if (!generalParams?.general) {
      len = undefined
    } else if (generalParams.model === 'sequence') {
      len =
        generalParams.general.match(/[ATCGNatcgn]{50,}/)?.[0].length ??
        undefined
    } else if (generalParams.model === 'region') {
      const matchRegion =
        generalParams.general
          .match(/:\d+-\d+/)?.[0]
          .slice(1)
          .split('-') ?? undefined
      len = matchRegion
        ? +matchRegion[1] >= +matchRegion[0]
          ? +matchRegion[1] - +matchRegion[0]
          : -1
        : undefined
    }
    return len
  }

  // 序列比对图
  const drawMatchPicture = async (
    template: string,
    left: string,
    right: string
  ) => {
    const newRight = right.split('').reduce((pre, cur) => {
      switch (cur) {
        case 'A':
          return 'T' + pre
        case 'T':
          return 'A' + pre
        case 'C':
          return 'G' + pre
        case 'G':
          return 'C' + pre
        default:
          return pre
      }
    }, '')

    const matchStr = template
      .replace(left, '>'.repeat(left.length))
      .replace(newRight, '<'.repeat(newRight.length))
      .replace(/[^<>]/g, ' ')

    const cols = 100
    const rows = Math.ceil(template.length / cols)
    const matchArray: string[] = []
    for (let i = 0; i < rows; i++) {
      const tem =
        (i * cols + 1 + '').padEnd(6, ' ') +
        template.slice(i * cols, (i + 1) * cols)

      const match =
        ' '.repeat(6) +
        matchStr.slice(i * cols, (i + 1) * cols).replace(/[><]+/gi, match => {
          switch (true) {
            case /<+/.test(match):
            case />+/.test(match):
              return `<em class="${styles['primer-match-primer']}">${match}</em>`
            default:
              return match
          }
        })

      matchArray.push(tem, match)
    }
    setMatchSeq(matchArray.join('\n'))
  }

  // region 提交
  const regionSubmit = async () => {
    if (checkParams(regionParams!)) {
      message.error('请将参数补充完整')
      setIsloading(false)
      return
    }
    const params = { ...regionParams, ...commonparams }
    const res = new Promise(() => { })
    return res
  }

  // variation 提交
  const variationSubmit = async () => {
    if (checkParams(variationParams!)) {
      message.error('请将参数补充完整')
      setIsloading(false)
      return
    }
    const params = { ...variationParams, ...commonparams }
    const res = new Promise(() => { })
    return res
  }

  // general 提交
  const generalSubmit = async () => {
    if (checkParams(generalParams!)) {
      message.error('请将参数补充完整')
      setIsloading(false)
      return
    }

    if (generalParams!.model === 'id') {
      const id = generalParams!.general
      if (!/\.{1,2}/.test(id) || /[^.\w]/.test(id)) {
        message.error('Please enter the correct gene ID or mRNA ID ！')
        setIsloading(false)
        return
      }
    } else if (generalParams!.model === 'region') {
      const region = generalParams!.general
      if (!/SL\d\.\d{1,2}ch\d{2}:\d+-\d+/.test(region)) {
        message.error('Please enter the correct region ！')
        setIsloading(false)
        return
      }
      const matchRegion = region
        .match(/:\d+-\d+/)?.[0]
        .slice(1)
        .split('-')!
      if (+matchRegion[1] < +matchRegion[0]) {
        message.error('Please enter the correct region ！')
        setIsloading(false)
        return
      } else if (+matchRegion[1] - +matchRegion[0] > 10000) {
        message.error('Region length must be less than 10K！')
        setIsloading(false)
        return
      }
    } else {
      const seq = generalParams!.general
      if (/![ATCGNatcgn]{50,}/.test(seq)) {
        message.error('Please enter the correct sequence ！')
        setIsloading(false)
        return
      }
    }

    const res = await primerService.general({
      ...generalParams,
      ...commonparams,
      general: generalParams!.general
        .split('\n')
        .filter(line => !!line)
        .join('\n')
        .trim()
    } as primerType.IGeneralBody)

    return res
  }

  // 提交按钮点击 提交
  const handleSubmit = async () => {
    try {
      setIsloading(true)
      setPrimerData(undefined)
      setMatchSeq('')

      if (checkParams(commonparams!)) {
        message.error('请将基础参数补充完整')
        setIsloading(false)
        return
      }
      const res: any =
        designType === 'region'
          ? await regionSubmit()
          : designType === 'variation'
            ? await variationSubmit()
            : await generalSubmit()

      if (!res) {
        // message.error('没有返回值')
        return
      }

      if (res.stat === 'ok') {
        const data = res.data.rows
        setPrimerData(data)
        drawMatchPicture(data[0].template, data[0].sequence, data[1].sequence)
        resultDivRef.current?.scrollIntoView({
          behavior: 'smooth'
        })
      } else {
        if (checkStat(res.stat)) {
          message.error(res.msg)
        } else {
          message.error('error')
        }
      }
    } catch (e) {
      console.log(e)
    }
    setIsloading(false)
  }

  const download = () => {
    const title =
      '#\tType\tSequence\tTm (℃)\tGC (%)\tPosition\tLength\tProduct Size\n'
    const table = primerData!
      .map(item =>
        [
          item.name,
          item.type,
          item.sequence,
          item.Tm,
          item.GC,
          item.position,
          item.len,
          item.productSize
        ].join('\t')
      )
      .join('\n')

    downloadFile(
      URL.createObjectURL(
        new Blob([title + table], {
          type: 'text/plain' + ';charset=' + 'utf-8'
        })
      ),
      'primer.txt'
    )
  }

  const columns: ColumnsType<primerType.IPrimerItem> = useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => (
          <span
            style={{ color: '#1890FF', cursor: 'pointer' }}
            onClick={() => {
              const PrimerArray = primerData!.filter(
                item => item.name === record.name
              )
              drawMatchPicture(
                PrimerArray[0].template,
                PrimerArray[0].sequence,
                PrimerArray[1].sequence
              )
              matchPreRef.current?.scrollIntoView({
                behavior: 'smooth'
              })
            }}
          >
            {record.name}
          </span>
        )
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Primer',
        dataIndex: 'sequence',
        key: 'sequence',
        render: (_, record) => (
          <pre key={record.sequence}>{record.sequence}</pre>
        )
      },
      {
        title: 'Tm (℃)',
        dataIndex: 'Tm',
        key: 'Tm'
      },
      {
        title: 'GC (%)',
        dataIndex: 'GC',
        key: 'GC'
      },
      {
        title: 'Start',
        dataIndex: 'position',
        key: 'position'
      },
      {
        title: 'Length',
        dataIndex: 'len',
        key: 'len'
      },
      {
        title: 'Product Size',
        dataIndex: 'productSize',
        key: 'productSizeype'
      }
    ],
    [primerData]
  )

  useEffect(() => {
    const len = checkGeneralSeqLen()
    setTemplateLen(len && len >= 0 ? len : undefined)
  }, [generalParams])

  return (
    <div className={styles.container}>
      <h1>Primer Design</h1>
      <p>
        If you have not used this tool, please read the{' '}
        <span
          style={{ color: '#FF4D4D', cursor: 'pointer' }}
          onClick={() => history.push('/tutorial')}
        >
          Tutorial
        </span>{' '}
        before using it.
      </p>
      <div className={styles.content}>
        <Tabs
          className={styles.tabs}
          defaultValue={designType}
          onChange={type => setDesignType(type)}
          type="card"
        >
          <TabPane tab="By Region" key="region" className={styles.tabPane}>
            <PDRegion setParams={params => setRegionParams(params)} />
          </TabPane>
          <TabPane
            tab="By Variation ID"
            key="variationId"
            className={styles.tabPane}
          >
            <PDVariation setParams={params => setVariationParams(params)} />
          </TabPane>
          <TabPane tab="By General" key="general" className={styles.tabPane}>
            <PDGeneral setParams={params => setGeneralParams(params)} />
          </TabPane>
        </Tabs>

        <Parameter
          templateLen={templateLen}
          setParams={params => setCommonParams(params)}
        />

        <div className={styles.btn_contaienr}>
          <Button type="primary" loading={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>

      <div>
        {primerData && (
          <>
            <Button size="middle" onClick={download}>
              Download
            </Button>
            <div ref={resultDivRef}>
              <Table
                className={styles.table}
                size='small'
                style={{
                  flex: 1
                }}
                columns={columns}
                rowKey={record => record.sequence + record.position}
                dataSource={primerData}
                pagination={{
                  position: ['topLeft'],
                  pageSize: 10
                }}
              // onRow={record => {
              //   return {
              //     onClick: () => {
              //       const PrimerArray = primerData.filter(
              //         item => item.name === record.name
              //       )
              //       drawMatchPicture(
              //         PrimerArray[0].template,
              //         PrimerArray[0].sequence,
              //         PrimerArray[1].sequence
              //       )
              //       matchPreRef.current?.scrollIntoView({
              //         behavior: 'smooth'
              //       })
              //     }
              //   }
              // }}
              />
              <pre
                ref={matchPreRef}
                className={styles.preMatch}
                style={{
                  fontSize: 18
                }}
                dangerouslySetInnerHTML={{ __html: matchSeq }}
              ></pre>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PrimerDesign
