// import styles from './style.module.less'
import './style.less'

interface Props {
  type?: 'normal' | 'PD' | 'PDpro' | 'pro'
  title: string | JSX.Element
  component: JSX.Element
  classname?: string
  afterComponent?: JSX.Element
  width?: number | string
  titlewidth?: number | string
}

const CONFIG = {
  normal: {
    width: 560,
    titlewidth: 160
  },
  PD: {
    width: 480,
    titlewidth: 280
  },
  PDpro: {
    width: 'auto',
    titlewidth: 280
  },
  pro: {
    width: 560,
    titlewidth: 280
  }
}

const TVDInput = (props: Props) => {
  const { type, title, component } = props
  let { width, titlewidth } = props

  if (!width && !titlewidth) {
    if (type) {
      width = CONFIG[type].width
      titlewidth = CONFIG[type].titlewidth
    } else {
      width = 560
      titlewidth = 160
    }
  }

  const compnentWidth =
    typeof width === 'undefined'
      ? 'auto'
      : isNaN(+width)
      ? 'auto'
      : +width - +(titlewidth as string | number)

  return (
    <div
      style={{ width }}
      className={'tic_input_container' + ' ' + props.classname}
    >
      <div style={{ width: titlewidth }} className="tic_input_title">
        {title}
      </div>

      <div
        style={{
          width: compnentWidth
        }}
        className="tic_component"
      >
        {component}
      </div>
      {props.afterComponent && (
        <div style={{ position: 'relative' }}>
          <div className="tic_after">{props.afterComponent}</div>
        </div>
      )}
    </div>
  )
}

export default TVDInput
