import { Input } from 'antd'
import './style.less'

interface Props {
  textareawidth?: number | string
  titlewidth?: number | string
  height?: number | string
  title?: string
  example?: string
  placeholder?: string
  setExample?: Function
  value?: string
  onChange: (v: string) => void
  [key: string]: any
}

const TVDTextarea = (props: Props) => {
  const { example, title, onChange, setExample, value, ...textareaProps } =
    props
  return (
    <div className="tt_container">
      {title && (
        <div
          className="tt_title"
          style={{
            width: props.titlewidth,
            height: props.height,
            lineHeight: props.height ? +props.height + 'px' : undefined
          }}
        >
          {title}
          {example && (
            <span
              className="example"
              onClick={() => setExample && setExample()}
            >
              {example}
            </span>
          )}
        </div>
      )}
      <div className="tt_textarea" style={{ height: props.height }}>
        <Input.TextArea
          {...textareaProps}
          style={{ width: props.textareawidth, height: props.height }}
          value={value}
          onChange={v => onChange(v.target.value)}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  )
}

export default TVDTextarea
