import {
  Switch,
  Route,
  Redirect,
  useHistory,
  withRouter
} from 'react-router-dom'
import { Menu, BackTop, message } from 'antd'
import { Footer } from 'antd/lib/layout/layout'
import { CRGV, homeImg4 } from '@/assets'
import routes from './routes'
import styles from './style.module.less'
import '@amap/amap-jsapi-types'
import { useEffect, useState } from 'react'

message.config({
  maxCount: 1,
  duration: 2
})

export const switchKey = (key: string) => {
  const path = window.location.pathname
  switch (key) {
    case 'Home':
      return '/'
    case 'Accession':
      return '/accession'
    case 'Population':
      return '/population'
    case 'Phenotype':
      return '/phenotype'
    case 'Variation':
      return '/search/variation'
    case 'GWAS':
      return '/search/gwas'
    case 'eQTL':
      return '/search/eqtl'
    case 'sQTL':
      return '/search/sqtl'
    case 'Gene':
      return '/search/gene'
    case 'Expression':
      return '/search/expression'
    case 'Selection Signals':
      return '/search/selection'
    case 'Introgression':
      return '/search/introgression'
    case 'Genome':
      return '/pan/genome'
    case 'Transcriptome':
      return '/pan/transcriptome'
    case 'PAV':
      return '/pan/pav'
    case 'Converter':
      return '/tools/converter'
    case 'BLAST':
      return '/tools/blast'
    case 'Genome Browser':
      window.open('http://www.sol5g.com/jbrowse2', '_blank')
      return path
    case 'Haplotype Network Analysis':
      return '/tools/analysis'
    case 'Primer Design':
      return '/tools/primer'
    case 'Download':
      return '/download'
    case 'Tutorial':
      return '/tutorial'
    case 'Contact':
      return '/contact'
    default:
      return '/'
  }
}

export const switchUrl = (url: string) => {
  const path = window.location.pathname
  switch (url) {
    case '/':
      return 'Home'
    case '/accession':
      return 'Accession'
    case '/population':
      return 'Population'
    case '/phenotype':
      return 'Phenotype'
    case '/search/variation':
      return 'Variation'
    case '/search/gwas':
      return 'GWAS'
    case '/search/eqtl':
      return 'eQTL'
    case '/search/sqtl':
      return 'sQTL'
    case '/search/gene':
      return 'Gene'
    case '/search/expression':
      return 'Expression'
    case '/search/selection':
      return 'Selection Signals'
    case '/search/introgression':
      return 'Introgression'
    case '/pan/genome':
      return 'Genome'
    case '/pan/transcriptome':
      return 'Transcriptome'
    case '/pan/pav':
      return 'PAV'
    case '/tools/converter':
      return 'Converter'
    case '/tools/blast':
      return 'BLAST'
    case '/tools/browser':
      window.open('http://www.sol5g.com/jbrowse2', '_blank')
      return path
    case '/tools/analysis':
      return 'Haplotype Network Analysis'
    case '/tools/primer':
      return 'Primer Design'
    case '/download':
      return 'Download'
    case '/tutorial':
      return 'Tutorial'
    case '/contact':
      return 'Contact'
    default:
      return 'Home'
  }
}

const App = () => {
  const { SubMenu } = Menu
  const history = useHistory()
  const [activeKey, setActiveKey] = useState<string>('')

  const handleMenuItemClick = (e: { key: string }) => {
    const url = switchKey(e.key)
    history.push(url)
  }

  useEffect(() => {
    setActiveKey(switchUrl(history.location.pathname))
  })

  return (
    <>
      <h2 className={styles.h2}>
        <img src={homeImg4} alt="" />
        The Tomato Genomic Variation Database
      </h2>
      <nav className={styles.header}>
        <Menu
          mode="horizontal"
          theme="dark"
          className={styles.menu}
          onClick={handleMenuItemClick}
          selectedKeys={[activeKey]}
        >
          <Menu.Item key="Contact">Contact</Menu.Item>
          <Menu.Item key="Tutorial">Tutorial</Menu.Item>
          <Menu.Item key="Download">Download</Menu.Item>
          <SubMenu key="Tools" title="Tools">
            <Menu.ItemGroup>
              <Menu.Item key="Converter">Genome Coordinate Converter</Menu.Item>
              <Menu.Item key="BLAST">BLAST</Menu.Item>
              <Menu.Item key="Genome Browser">Genome Browser</Menu.Item>
              <Menu.Item key="Haplotype Network Analysis">
                Haplotype Network Analysis
              </Menu.Item>
              <Menu.Item key="Primer Design">Primer Design</Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="Pan" title="Pan-genome">
            <Menu.ItemGroup>
              <Menu.Item key="Genome">Genome</Menu.Item>
              <Menu.Item key="Transcriptome">Transcriptome</Menu.Item>
              <Menu.Item key="PAV">PAV</Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="Search" title="Search">
            <Menu.ItemGroup>
              <Menu.Item key="Variation">Variation</Menu.Item>
              <Menu.Item key="GWAS">GWAS</Menu.Item>
              <Menu.Item key="eQTL">eQTL</Menu.Item>
              <Menu.Item key="sQTL">sQTL</Menu.Item>
              <Menu.Item key="Gene">Gene</Menu.Item>
              <Menu.Item key="Expression">Population Gene Expression</Menu.Item>
              <Menu.Item key="Selection Signals">Selection Signals</Menu.Item>
              <Menu.Item key="Introgression">Introgression</Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>

          <SubMenu key="AccessionP" title="Accession">
            <Menu.ItemGroup>
              <Menu.Item key="Accession">Accession Information</Menu.Item>
              <Menu.Item key="Phenotype">Phenotype Information</Menu.Item>
              <Menu.Item key="Population">Population Structure</Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <Menu.Item key="Home">TVD</Menu.Item>
        </Menu>
      </nav>
      <main className={styles.main}>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
          <Redirect from="/*" to="/" />
        </Switch>
      </main>
      <BackTop />
      <Footer className={styles.footer}>
        Key Laboratory of Horticultural Plant Biology, Huazhong Agricultural
        University of China, Hubei, Wuhan 430070,China. E-mail:
        <a style={{ marginLeft: 5 }} href="mailto:yuhuiyang1234@126.com">
          yuhuiyang1234@126.com
        </a>
        <div className='backup'>
          <div>
            <span
              onClick={() => {
                window.open('https://beian.miit.gov.cn/', '_blank')
              }}
            >
            备案号：黔ICP备 2022004498号-1
            </span>
          </div>
          <div style={{ width: '300px', margin: '0 auto', padding: '20px 0' }}>
          <a target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52263102000184" 
            style={{ display: 'inline-block', textDecoration: 'none', height: '20px', lineHeight: '20px' }}
            rel="noreferrer"
          >
            <img src={CRGV} style={{ float: 'left' }}/>
            <p style={{ float: 'left', height: '20px', lineHeight: '20px', margin: '0px 0px 0px 5px', color: '#fff' }}>贵公网安备 52263102000184号</p></a>
        </div>
        </div>
        

      </Footer>
    </>
  )
}

export default withRouter(App)
