import { createElement, useEffect, useMemo, useRef, useState } from 'react'
import { Button, message, Select, Table, Tabs, Input } from 'antd'
import { popService } from '@/services'
import { popType } from '@/types'
import * as Plotly from 'plotly.js-dist-min'
import styles from './style.module.less'
import { downloadFile, sleep } from '@/utils'
import { ColumnsType } from 'antd/lib/table'
import { TVDInputContainer } from '@/components'

const COLOR = ['74d2e7', '0085ad', 'ff9933', 'fe5000']

const CONFIG = {
  width: 520 + 160,
  titlewidth: 160,
  textareawidth: 520
}

const Population = () => {
  const { Search } = Input
  const { Option } = Select
  const divRef = useRef<HTMLDivElement>(null)
  const [kmer, setKmer] = useState<popType.Kmer>('K2')

  const [plotData, setPlotData] = useState<popType.PopItem[]>()
  const [valTypes, setValTypes] = useState<number>(0)
  const [groups, setGroups] = useState<string[]>([])
  const [limit, setLimit] = useState(10)
  const [current, setCurrent] = useState(1)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState<popType.PopItem[]>()

  const columns: ColumnsType<popType.PopItem> = useMemo(() => {
    return [
      ...[
        {
          title: 'No.',
          dataIndex: 'index',
          key: 'index',
          width: 45
        },
        {
          title: 'Accession',
          dataIndex: 'accession',
          key: 'accession',
          width: 100
        },
        {
          title: 'Group',
          dataIndex: 'group',
          key: 'group',
          width: 70
        },
        {
          title: 'Taxon',
          dataIndex: 'taxon',
          key: 'taxon',
          width: 150
        }
      ],
      ...[...new Array(valTypes)].map((_, i) => ({
        title: `q${i + 1}`,
        dataIndex: `q${i + 1}`,
        key: `q${i + 1}`
        // sorter: (a: popType.PopItem, b: popType.PopItem) =>
        //   +a[`q${i + 1}`] - +b[`q${i + 1}`]
      }))
    ]
  }, [valTypes])

  const drawPlot = async (oridata: popType.PopItem[], valTypes: number) => {
    const x = [...oridata.map(item => item.accession)]
    const data: Plotly.Data[] = [...new Array(valTypes)].map((_, i) => {
      return {
        x,
        y: oridata.map(item => item[`q${i + 1}`]),
        type: 'bar',
        name: `q${i + 1}`,
        marker: {
          // color: COLOR[i + 1]
        }
      }
    })
    const layout: Partial<Plotly.Layout> = {
      title: kmer,
      xaxis: {
        automargin: true,
        showticklabels: false
      },
      yaxis: {
        showline: true,
        automargin: true,
        ticklen: 5,
        tick0: 2
      },
      barmode: 'stack'
    }
    const config: Partial<Plotly.Config> = {
      toImageButtonOptions: {
        filename: `population_structure_${kmer}.svg`,
        format: 'svg'
      },
      displaylogo: false
    }

    const plotlyDiv = await Plotly.newPlot(
      divRef.current!,
      data,
      layout,
      config
    )
    // plotlyDiv.scrollIntoView({
    //   behavior: 'smooth'
    // })
  }

  const getData = async () => {
    const compare = (
      itemA: popType.PopItem,
      itemB: popType.PopItem,
      valTypes: number
    ) => {
      for (let i = 0; i < valTypes; i++) {
        if (+itemA[`q${i + 1}`] - +itemB[`q${i + 1}`]) {
          return +itemB[`q${i + 1}`] - +itemA[`q${i + 1}`]
        }
      }
      return 1
    }

    setLoading(true)
    const res = await popService.getData(kmer)

    if (res.stat === 'ok') {
      setValTypes(res.data.valTypes)
      setGroups(res.data.groups)

      res.data.rows.sort((a, b) => compare(a, b, res.data.valTypes))
      const data = res.data.rows.map((item, i) => ({ ...item, index: i + 1 }))

      setPlotData(data)
      setTableData(data)
    } else {
      message.error('No Data')
    }
  }

  const filterTabsTableData = (
    data: popType.PopItem[],
    keyword: string = ''
  ) => {
    setTableData(
      keyword
        ? data?.filter(
            item =>
              new RegExp(keyword, 'ig').test(item.accession) ||
              new RegExp(keyword, 'ig').test(item.group)
          )
        : data
    )
  }

  useEffect(() => {
    tableData && setLoading(false)
  }, [tableData])

  useEffect(() => {
    getData()
  }, [kmer])

  useEffect(() => {
    plotData?.length && drawPlot(plotData, valTypes)
  }, [plotData])

  return (
    <div className={styles.container}>
      <h1>Population Structure</h1>
      <p></p>

      <div className={styles.content}>
        <TVDInputContainer
          {...CONFIG}
          title="K"
          component={
            <Select value={kmer} onChange={(v: popType.Kmer) => setKmer(v)}>
              <Option value="K2">K2</Option>
              <Option value="K3">K3</Option>
              <Option value="K4">K4</Option>
              <Option value="K5">K5</Option>
              <Option value="K6">K6</Option>
              <Option value="K7">K7</Option>
              <Option value="K8">K8</Option>
              <Option value="K9">K9</Option>
              <Option value="K10">K10</Option>
              <Option value="K11">K11</Option>
              <Option value="K12">K12</Option>
              <Option value="K13">K13</Option>
              <Option value="K14">K14</Option>
              <Option value="K15">K15</Option>
              <Option value="K16">K16</Option>
              <Option value="K17">K17</Option>
              <Option value="K18">K18</Option>
              <Option value="K19">K19</Option>
              <Option value="K20">K20</Option>
            </Select>
          }
        />

        <div
          style={{ display: plotData?.length ? 'block' : 'none' }}
          ref={divRef}
          className={styles.plot}
        ></div>

        <div
          className={styles.result_table}
          style={{ display: plotData?.length ? 'block' : 'none' }}
        >
          <div className={styles.tableBtn}>
            <Search
              placeholder="Accession or Group"
              enterButton="search"
              size="middle"
              // defaultValue={'5'}
              onSearch={keyword => filterTabsTableData(plotData ?? [], keyword)}
            />
            <Button
              size="middle"
              onClick={() => {
                const title = `Accession\tGroup\tTaxon\t${[
                  ...new Array(valTypes)
                ]
                  .map((_, i) => `q${i + 1}`)
                  .join('\t')}\n`
                const table = tableData
                  ?.map(item =>
                    [
                      item.accession,
                      item.group,
                      item.taxon,
                      item.value,
                      ...[...new Array(valTypes)].map(
                        (_, i) => item[`q${i + 1}`]
                      )
                    ].join('\t')
                  )
                  .join('\n')

                downloadFile(
                  URL.createObjectURL(
                    new Blob([title + table], {
                      type: 'text/plain' + ';charset=' + 'utf-8'
                    })
                  ),
                  `population_structure_${kmer}.txt`
                )
              }}
            >
              Download
            </Button>
          </div>
          <Table
            sticky
            className={styles.table}
            columns={columns}
            dataSource={tableData}
            loading={loading}
            scroll={{ x: 1500 }}
            size="small"
            rowKey="accession"
            bordered={true}
            pagination={{
              current,
              pageSize: limit,
              position: ['topLeft', 'bottomLeft']
            }}
            onChange={pagination => {
              setCurrent(pagination.current as number)
              setLimit(pagination.pageSize as number)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Population
