import { message } from 'antd'
import axios from 'axios'

const instance = axios.create({
  validateStatus: status => {
    return status < 500
  }
})

// instance.interceptors.request.use(req => {
//   for (const item in req.data) {
//     if (req.data[item] === undefined || (item === 'followId' && req.data[item] === '')) {
//       delete req.data[item]
//     }
//   }
//   return req
// })

// instance.interceptors.response.use(res => {
//   if (res.data.stat !== 'ok') {
//     message.destroy('error')
//     message.error({
//       content: res.data.msg,
//       duration: 1,
//       key: 'error'
//     })
//     return false
//   }
//   return res.data.data
// })

export interface BaseRes<T = any> {
  stat: string
  data: T
  msg: string
}

export default instance
